import { useCallback, useEffect, useMemo, useState } from 'react';
import useSurveyStore from '../store/survey.store';
import { AnswerDTO } from '../api/types';
import * as jose from 'jose';

const useApi = () => {
  const { question, survey_id, setSurveyId, postAnswer, begin } = useSurveyStore();
  const [loading, setLoading] = useState(false);
  const cookies = document.cookie.split(';');
  const accessToken = cookies.find((cookie) => cookie.includes('access_token'))?.split('=')[1];

  const [answer, setAnswer] = useState<AnswerDTO | null | undefined>([]);
  
  const canProceed = useMemo(() => {
    if (question?.page.page_name === 'ncs_map_selection') {
      if (!answer || !answer[0].answers[0]?.coordinates) return false;
      if ((answer[0].answers[0].coordinates as number[][][]).length > 1) return true; 
      return Object.keys(answer[0].answers[0].coordinates).length > 0;
    }
    if (question?.page.page_name === 'ncs_map_selection_loop') {
      if (!answer) return false;
      return answer?.some((element) => element.answers.length > 0);
    }
    return answer?.every((element) => element.answers.length > 0) ?? false;
  }, [answer, question?.page.page_name]);

  useEffect(() => {
    setAnswer(prev => question?.page.questions.map((question) => {
      return {
        questionId: question.question_id,
        answers: [...prev?.find(e => e.questionId === question.question_id)?.answers ?? []],
      };
    }));
  }, [question]);

  useEffect(() => {
    let payload =  null;
    if (accessToken) {
      payload = jose.decodeJwt(accessToken);
    }

    // get survvey_id from query params
    const urlParams = new URLSearchParams(window.location.search);
    const surveyId = urlParams.get('survey_id');
    if (!surveyId) return;
    setLoading(true);
    
    if (!loading && !survey_id && payload?.sub !== survey_id) begin(surveyId);
    // if (surveyId && !survey_id) setSurveyId(surveyId);
  }, [accessToken, begin, loading, setSurveyId, survey_id]);

  const next = useCallback(() => {
    if (survey_id) postAnswer(answer ?? []);
  }, [answer, postAnswer, survey_id]);

  const answerQuestion = useCallback((questionId: string, answers: AnswerDTO[0]['answers']) => {
    setAnswer((prev) => {
      const newAnswer = [...prev ?? []];
      const index = newAnswer.findIndex((element) => element.questionId === questionId);
      if (index === -1) return [...prev ?? [], { questionId, answers }];
      newAnswer[index].answers = answers;
      
      return newAnswer;
    });
  }, [setAnswer]);

  return { canProceed, next, answerQuestion };
};

export default useApi;
