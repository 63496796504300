import React from 'react';

import style from './card-placeholder.module.scss';

interface CardProps {
  children?: React.ReactNode;
}

const CardPlaceholder = ({
  children,
}: CardProps): JSX.Element => {
  return <div className={style.card_placeholder}>
    {children}
  </div>;
};

export default CardPlaceholder;
