import React, { ChangeEvent } from 'react';
import style from './input.module.scss';

interface InputProps {
  children?: never[],
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  valid?: boolean;
}

const Input = ({ placeholder, disabled, type, valid = true, onChange, value }: InputProps): JSX.Element => {

  return (
    <div className={`${style.input} ${!valid ? style.notValid : ''}`}>
      <input
        disabled={disabled}
        type={`${type ?? 'text'}`}
        placeholder={`${placeholder ?? 'Your answer'}`}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default Input;
