/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import Question from '../../components/question/question';
import MapComponent from '../../components/map/map';
import style from './question-map.module.scss';
import { QuestionProps } from '../../types';
import { GeoJSON } from '../../types';
import { convertToGeoJson } from '../util';
import { getAdministrativeBoundaries } from '../../api/backend/api';
import { useCursorMovedOutside } from '../../hooks/util';
import useSurveyStore from '../../store/survey.store';
import { capitalize } from 'lodash';

const MapQuestion: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answerQuestion,
}) => {
  const { setAlert } = useSurveyStore();
  const [selected, setSelected] = useState<GeoJSON | null>(
    convertToGeoJson(
      (question.previous_answer?.answers[0].answers[0] as any)?.coordinates
    ) ?? null
  );

  const [selectedBoundaries, setSelectedBoundaries] = useState<
    {
      name: string;
      country: string;
      geometry: GeoJSON;
    }[]
  >([]);

  const [freeSelect, setFreeSelect] = useState<boolean>(false);

  useEffect(() => {
    if (
      (
        question.previous_answer?.answers[0].answers[0]
          .coordinates as number[][][]
      )?.length > 0
    ) {
      setFreeSelect(true);
      setSelectedBoundaries([]);
      setSelected({
        type: 'Polygon',
        coordinates: question.previous_answer?.answers[0].answers[0]
          .coordinates as any,
      });
      return;
    }
    const countries = question.previous_answer?.answers[0].answers[0]
      .coordinates as { [key in string]: string[] };
    if (!countries) {
      return;
    }
    Object.keys(countries).forEach((country) => {
      getAdministrativeBoundaries(country, null, countries[country]).then(
        (res) => {
          setSelectedBoundaries((prev) => [
            ...prev,
            ...res.boundaries.map((e) => ({
              name: e.name,
              country: country,
              geometry: e.geometry,
            })),
          ]);
        }
      );
    });
  }, [question.previous_answer?.answers]);

  const countriesGrouped = useMemo(() => {
    if (selectedBoundaries.length > 0) {
      setAlert({
        type: 'info',
        message: `You have selected an area in ${selectedBoundaries[0].country} 
        (${capitalize(selectedBoundaries[0].name)}), please press NEXT or click 
        this polygon again to un-select this place.`,
      });
    }
    return selectedBoundaries.reduce((acc: any, curr) => {
      if (acc[curr.country]) {
        acc[curr.country].push(curr.name);
      } else {
        acc[curr.country] = [curr.name];
      }
      return acc;
    }, {});
  }, [selectedBoundaries]);

  useEffect(() => {
    answerQuestion(question.page.questions[0].question_id, [
      {
        coordinates: freeSelect ? selected?.coordinates : countriesGrouped,
      },
    ]);
  }, [
    answerQuestion,
    countriesGrouped,
    freeSelect,
    question.page.questions,
    selected,
  ]);

  const questionRef = React.useRef<HTMLDivElement>(null);

  useCursorMovedOutside(questionRef, () => {
    // setSelected(null);
  });

  return (
    <Question
      ref={questionRef}
      hasPadding={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.question}>
        <div className={style.title}>
          <h1
            dangerouslySetInnerHTML={{
              __html: question.page.questions[0].question_display
                ? question.page.questions[0].question_display
                : '',
            }}
          ></h1>
          <h2
            dangerouslySetInnerHTML={{
              __html: question.page.questions[0].question_helper
                ? question.page.questions[0].question_helper
                : '',
            }}
          ></h2>
        </div>{' '}
        <div className={style.map}>
          <MapComponent
            selectedBoundaries={selectedBoundaries}
            selected={selected}
            freeSelect={freeSelect}
            onCountrySelected={setSelected}
            onFreeSelectStateChange={setFreeSelect}
            onBoundariesSelected={setSelectedBoundaries}
          />
        </div>
      </div>
    </Question>
  );
};

export default MapQuestion;
