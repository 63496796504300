import React from 'react';
import Question from '../../components/question/question';
import style from './dce-to-region.module.scss';
import { QuestionProps } from '../../types';

const IncentiveInfoPage: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
}) => {
  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <h1>{question.page.page_title}</h1>
        <div className={style.parent}>
          <div className={style.child_text}>
            <i>Thank you for completing the hypothetical game!</i>
          </div>
          <div className={style.child_text}>
            <b>We would now like to ask you about your real experiences.<br/>
            The remaining questions relate to your own experiences and empirical work.</b>
          </div>
          <div className={style.child_text}>
            Please provide answers based on your personal and general experience<br/>
            (we understand precise answers may be difficult for some questions).
          </div>
        </div>
      </div>
    </Question>
  );
};

export default IncentiveInfoPage;
