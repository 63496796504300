import ky from 'ky';
import { env } from '../env';

export const client = ky.create({
  prefixUrl: env.apiBaseUrl,
  headers: {
    'Access-Control-Allow-Credentials' : 'true',
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'GET',
    'Access-Control-Allow-Headers':'application/json',
  },
  credentials: 'include',
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          const cookies = document.cookie.split(';').map((c) => c.trim());
          const access_token = cookies.find((c) => c.startsWith('access_token='))?.split('=')[1];
          if (access_token) {
            const newResponse = await client(request);
            return newResponse;
          }
        }
      },
    ],
  }
});
