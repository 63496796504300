/* eslint-disable @typescript-eslint/no-explicit-any */
import { LatLngExpression } from 'leaflet';
import React, { useCallback, useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, GeoJSON, useMapEvents, Polyline, Marker, CircleMarker } from 'react-leaflet';

import * as L from 'leaflet';
import 'leaflet.heat';

import style from './evidence-map.module.scss';
import { getAdministrativeBoundaries } from '../../api/backend/api';

import * as Types from '../../types';
import { getCenter, getZoom } from '../../pages/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import useSurveyStore from '../../store/survey.store';

const MAP_URL = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png';
const CONNECT_THRESHOLD = 10;

interface EvidenceMapProps {
  selectable?: boolean;
  evidence_map?: number[][];
  selected?: Types.GeoJSON[] | null;
  setSelection?: (selection: Types.GeoJSON[] | null) => void;
  selected_region?: {
    [key: string]: string[];
  } | number[][][];
  zoom?: number;
}

const EvidenceMapLeafletComponent: React.FC<EvidenceMapProps> = ({ 
  selected,
  setSelection,
  evidence_map,
  selectable,
  zoom,
}) => {
  
  const map = useMap();

  const [freeSelection, setFreeSelection] = useState<L.LatLng[] | null>();
  const [drawConnect, setDrawConnect] = useState(false);

  const { setAlert } = useSurveyStore();

  useMapEvents({
    click: (e) => {
      if (!selectable || !zoom) return;
      if (!freeSelection) {
        setFreeSelection([e.latlng]);
      }
      else {
        if (freeSelection.length > 2 && 
          map.latLngToLayerPoint(freeSelection[0]).distanceTo(e.layerPoint) < CONNECT_THRESHOLD) {
          const nextSelection: Types.GeoJSON[] = selected ? [...selected, {
            type: 'Polygon',
            coordinates: [
              freeSelection
                .map((e) => [e.lng, e.lat]) as any,
            ],
          }] : [{
            type: 'Polygon',
            coordinates: [
              freeSelection
                .map((e) => [e.lng, e.lat]) as any,
            ],
          }];
          setFreeSelection(null);
          setDrawConnect(false);
          setSelection?.(nextSelection);
          return;
        }else {
          setFreeSelection([...freeSelection, e.latlng]);
        }
      }
    },
    mousemove: (e) => {
      if (!zoom) return;
      if (freeSelection && freeSelection.length > 2) {
        if (map.latLngToLayerPoint(freeSelection[0]).distanceTo(e.layerPoint) < CONNECT_THRESHOLD) {
          setDrawConnect(true);
        } else {
          setDrawConnect(false);
        }
      }
    },
  });

  useEffect(() => {
    if (selected && selected.length > 4 && freeSelection) {
      setAlert({
        type: 'error',
        message: 'You can only select up to 5 regions',
      });
      setFreeSelection(null);
    }
  }, [freeSelection, selected, setAlert]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const heat = (L as any).heatLayer(evidence_map, {
      radius: 18,
      blur: 10,
      maxZoom: 18,
      minOpacity: 0.2,
      max: 10,
      gradient: {
        0.4: 'blue',
        0.6: 'cyan',
        0.7: 'lime',
        0.8: 'yellow',
        1.0: 'red',
      },
    }).addTo(map);

    return () => {
      map.removeLayer(heat);
    };

  }, [evidence_map, map]);

  const onAccept = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (!freeSelection || freeSelection.length < 3) return;
    const nextSelection: Types.GeoJSON[] = selected ? [...selected, {
      type: 'Polygon',
      coordinates: [
        freeSelection
          .map((e) => [e.lng, e.lat]) as any,
      ],
    }] : [{
      type: 'Polygon',
      coordinates: [
        freeSelection
          .map((e) => [e.lng, e.lat]) as any,
      ],
    }];
    setSelection?.(nextSelection);
    setFreeSelection(null);
  }, [freeSelection, selected, setSelection]);
    
  const onClose = useCallback(() => {
    setFreeSelection(undefined);
    setSelection?.(null);
    setDrawConnect(false);
  }, [setSelection]);

  const renderControls = useCallback(() => {
    return (
      <div id='overlay' className={style.selectionMode}>
        <div className={style.freeSelectOptions}>
          <button onClick={onClose} className={style.close}>
            <FontAwesomeIcon icon={faX} />
          </button>
          <button
            onClick={onAccept} className={`${style.accept}
                ${(!freeSelection || freeSelection.length < 3) && style.disabled}`}>
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      </div>
    );
  }, [freeSelection, onAccept, onClose]);
  
  const div = L.DomUtil.get('overlay');
  if (div) {
    L.DomEvent.disableClickPropagation(div);
    L.DomEvent.disableScrollPropagation(div);
  }

  return (
    <div className={style.region}>
      <TileLayer url={MAP_URL} />
      {selectable && <>
        {renderControls()}
        {drawConnect && freeSelection && (
          <CircleMarker center={freeSelection[0]} radius={10} />
        )  
        }
        {freeSelection && (
          <Polyline pathOptions={{ color: 'red' }} positions={freeSelection} />
        )}
        {freeSelection && (
          <Marker position={freeSelection[freeSelection.length - 1]} />
        )}
        {selected?.map((region, index) => {
          return (
            <GeoJSON
              key={index}
              data={region}
              pathOptions={{ color: 'red' }}
            />
          );
        })}
      </>}
    </div>
  );
};

const EvidenceMap: React.FC<EvidenceMapProps> = ({ 
  selected,
  setSelection,
  selectable, 
  evidence_map, 
  selected_region
}) => {
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState<LatLngExpression>();
  const [selectedRegions, setSelectedRegions] = useState<Types.GeoJSON[]>();

  if (!evidence_map) return null;

  const getRegions = useCallback(async () => {
    if (!selected_region) return;

    const countries = Object.keys(selected_region);

    const regions = await Promise.all(countries.map(async (country) => {
      return await getAdministrativeBoundaries(country, null, (selected_region as any)[country]);
    }));

    const geojson = regions.map((region) => {
      return region.boundaries.map((boundary) => {
        return boundary.geometry;
      });
    });
    
    return geojson.flat();

  }, [selected_region]);

  useEffect(() => {
    if ((selected_region as number[][][])?.length > 0) {
      const merged = {
        type: 'Polygon',
        coordinates: (selected_region as any) as LatLngExpression[][][],
      } as Types.GeoJSON;
      const _center = getCenter(merged.coordinates as any);
      setCenter([_center[1], _center[0]]);
      const _zoom = getZoom(merged.coordinates as any);
      setZoom(_zoom);
      setSelectedRegions([merged]);
      return;
    }
    getRegions().then((geojson) => {
      if (!geojson) return;
      setSelectedRegions(geojson);

      const merged = geojson.reduce((acc, curr) => {
        return {
          type: 'MultiPolygon',
          coordinates: [...acc.coordinates, ...curr.coordinates],
        };
      });
      const _center = getCenter(merged.coordinates as any);
      
      setCenter([_center[1], _center[0]]);
      const _zoom = getZoom(merged.coordinates as any);
      
      setZoom(_zoom);
    });

  }, [getRegions, selected_region]);

  if (!center || !zoom) return null;
  return (
    <div className={style.region}>
      <div className={style.map}>
        <MapContainer
          style={{ height: '100%' }}
          center={center ?? [0, 0]}
          zoom={zoom ?? 0}
          scrollWheelZoom={true}
        >
          {
            selectedRegions && selectedRegions.map((region, i) => {
              return <GeoJSON
                style={{ fillColor: `${selectable ? '#2a2a2a' : '#88aa88'}`, color: `${selectable ? '#2a2a2a' : '#004400'}` }}
                key={`${JSON.stringify(region)}-${i}`}
                data={region}
              />;
            })
          }
          <EvidenceMapLeafletComponent
            selectable={selectable}
            zoom={zoom}
            selected={selected}
            setSelection={setSelection}
            selected_region={selected_region}
            evidence_map={evidence_map}
          />
        </MapContainer>
      </div>
    </div>
  );
};

export default EvidenceMap;
