import React, { ChangeEvent, useEffect, useState } from 'react';
import Question from '../../components/question/question';
import Input from '../../components/input/input';

import style from './lottery.module.scss';
import {
  LotteryInterface,
  NewPossibleAnswer,
  QuestionProps,
} from '../../types';
import Button from '../../components/button/button';
import useSurveyStore from '../../store/survey.store';
import RadioAnswer from '../../components/possible-answer/radio-answer/radio-answer';
import FreeTextAnswer from '../../components/possible-answer/free-text-answer/free-text-answer';

const Lottery: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answerQuestion,
}) => {
  const { submitLottery, setAlert } = useSurveyStore();
  const [result, setResult] = useState<number>();
  const [correctResult, setCorrectResult] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setResult(value);
    if (value == 45) {
      setCorrectResult(true);
    } else {
      setCorrectResult(false);
    }
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handleSubmit = async () => {
    if (validateEmail(email)) {
      const data: LotteryInterface = { e_mail: '', win_allocation: null };
      data.e_mail = email;
      data.win_allocation = [selectedAnswer, selectedDonation];
      submitLottery(data);
    } else {
      setAlert({
        message: 'Not a valid email address!',
        type: 'warning',
      });
    }
  };

  function validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const [selectedAnswer, setSelectedAnswer] = useState<NewPossibleAnswer>(
    question.previous_answer?.answers[0].answers[0] ||
      question.page.questions[0].possible_answers[0]
  );

  const [selectedDonation, setSelectedDonation] = useState<NewPossibleAnswer>({
    answer_id: '',
    answer_display: '',
    answer_name: '',
    user_input: false,
    type: '',
  });

  const [freeText, setFreeText] = useState<string>('');

  useEffect(() => {
    const data: NewPossibleAnswer = { ...selectedAnswer };

    answerQuestion(question.page.questions[0].question_id, [data]);
  }, [answerQuestion, question.page.questions, selectedAnswer]);

  useEffect(() => {
    const data: NewPossibleAnswer = { ...selectedDonation };

    answerQuestion(question.page.questions[1].question_id, [data]);
  }, [answerQuestion, question.page.questions, selectedDonation]);

  const handleSelectDonation = (donation: NewPossibleAnswer) => {
    setSelectedDonation(donation);
  };

  useEffect(() => {
    setSelectedDonation((prev) => {
      prev.answer_display = freeText;
      return prev;
    });
  }, [freeText]);

  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <h1>{question.page.page_title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: question.page.page_display
              ? question.page.page_display
              : '',
          }}
        ></p>
        <div>
          <div className={style.info_text}>
            In accordance with{' '}
            <a
              href="https://en.wikipedia.org/wiki/Skill_testing_question"
              target="_blank"
              rel="noreferrer"
            >
              Canadian regulations
            </a>
            , you must correctly respond to a mathematical skill-testing
            question in order to be eligible to win the draw:
          </div>
        </div>
        <div className={style.parent}>
          <div className={style.child_text}>
            <span style={{ paddingLeft: 64 }}>
              <b>( 5 x 10 ) - 5 = </b>
            </span>
          </div>
          <div className={style.child_text}>
            <Input
              placeholder="Your result comes here"
              type="number"
              onChange={handleInputChange}
              value={String(result)}
            ></Input>
          </div>
        </div>
        {correctResult && (
          <div className={style.parent_answers}>
            <div className={style.answers}>
              <p className={style.question_title}>
                {question.page.questions[0].question_display}
              </p>
              {question.page.questions[0].possible_answers.map(
                (possibleAnswer) => {
                  return (
                    <div key={possibleAnswer.answer_id}>
                      <>
                        <RadioAnswer
                          selected={
                            selectedAnswer?.answer_id ===
                            possibleAnswer.answer_id
                          }
                          onSelect={() => {
                            setSelectedAnswer(possibleAnswer);
                          }}
                          possibleAnswer={possibleAnswer}
                        />
                      </>
                    </div>
                  );
                }
              )}
            </div>

            <div className={style.answers2}>
              {question.page.questions[1].visibility_condition?.includes(
                selectedAnswer.answer_name
              ) && (
                <div>
                  <p>{question.page.questions[1].question_display}</p>
                  <div className={style.bioms}>
                    {question.page.questions[1].possible_answers.map(
                      (possibleAnswer) => {
                        return (
                          <div key={possibleAnswer.answer_id}>
                            {!possibleAnswer.user_input ? (
                              <>
                                <RadioAnswer
                                  selected={
                                    selectedDonation?.answer_id ===
                                    possibleAnswer.answer_id
                                  }
                                  onSelect={() => {
                                    handleSelectDonation(possibleAnswer);
                                  }}
                                  possibleAnswer={possibleAnswer}
                                />
                              </>
                            ) : (
                              <>
                                <FreeTextAnswer
                                  onClick={() =>
                                    handleSelectDonation(possibleAnswer)
                                  }
                                  selected={
                                    selectedDonation?.answer_id ===
                                    possibleAnswer.answer_id
                                  }
                                  possibleAnswer={possibleAnswer}
                                  value={freeText}
                                  onChange={(e: any) =>
                                    setFreeText(e.target.value)
                                  }
                                />
                              </>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {correctResult && (
          <div
            className={
              correctResult
                ? `${style.correct} ${style.parent}`
                : `${style.parent}`
            }
          >
            <div className={style.child_text}>
              Submit an email address to confirm your entry into the lottery. We
              will contact you at this address if you win.
            </div>
            <div className={style.child_text}>
              <Input
                placeholder="Your email address"
                onChange={(e) => {
                  handleEmailChange(e.target.value);
                }}
                type="text"
              ></Input>
            </div>
            <div className={style.tryOutMyLuck}>
              <Button onClick={handleSubmit}>Enter lottery!</Button>
            </div>
          </div>
        )}
      </div>
    </Question>
  );
};

export default Lottery;
