import React from 'react';
import BackButton from '../back-button/back-button';
import NextButton from '../next-button/next-button';
import ProgressBar from '../progress-bar/progress-bar';
import style from './navigation.module.scss';
import { NewQuestionInterface } from '../../types';
import { ProgressMap } from './navigation.helper';

interface NavigationProps {
  onNextButtonClickAction: () => void;
  onBackButtonClickAction: () => void;
  canProceed?: boolean;
  questionIds: number[];
  loading?: boolean;
  currentQuestion: number;
  question: NewQuestionInterface;
}

const Navigation = ({
  onBackButtonClickAction,
  onNextButtonClickAction,
  currentQuestion,
  loading,
  question,
  canProceed = true,
}: NavigationProps) => {
  return (
    <div className={style.navigation}>
      {
        question?.page.page_name !== 'progress' && (
          <ProgressBar
            c1={ProgressMap[question.page.page_name][0]}
            c2={ProgressMap[question.page.page_name][1]}
            c3={ProgressMap[question.page.page_name][2]}
            c4={ProgressMap[question.page.page_name][3]}
            c5={ProgressMap[question.page.page_name][4]}
            c6={ProgressMap[question.page.page_name][5]}
            c7={ProgressMap[question.page.page_name][6]}
          />
        )
      }

      <div className={style.buttons}>
        {(
          question?.page.page_name !== 'progress' &&
          currentQuestion !== 2 &&
          question.page.page_name !== 'thank_you') && (
          <BackButton disabled={loading} onClick={onBackButtonClickAction} />
        )}
        <NextButton
          disabled={loading || !canProceed}
          label={
            question.page.page_name == 'thank_you' ? 'Enter lottery!' :
              question.page.page_name == 'progress' ? 'Enter' : 'Next'
          }
          onClick={onNextButtonClickAction}
        />
      </div>
    </div>
  );
};

export default Navigation;
