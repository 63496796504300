import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RadioGroupAnswer, {
  RadioGroupAnwserType,
} from '../../components/possible-answer/radiogroup-answer/radiogroup-answer';
import Question from '../../components/question/question';
import {
  AnswerData,
  CoBenefit,
  QuestionData,
  QuestionProps,
} from '../../types';
import { CoBenefitIcons } from '../util';

import style from './question-multiselect-radio-group.module.scss';
import _ from 'lodash';

const MultiSelectRadioGroupQuestion: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answerQuestion,
}) => {
  const [selected, setSelected] = useState<AnswerData[] | undefined>(
    question.previous_answer?.answers
  );

  const cobenefits = useMemo(() => {
    return _.groupBy(question.page.questions, (e) => e.group_display?.at(1));
  }, [question]);

  const pathways = useMemo(() => {
    return _.groupBy(question.page.questions, (e) => e.group_display?.at(0));
  }, [question]);

  useEffect(() => {
    question.page.questions.forEach((e) => {
      const answer = selected?.find((a) => a.question_id === e.question_id);
      if (answer) {
        answerQuestion(e.question_id, answer.answers);
      }
    });
  }, [answerQuestion, question.page.questions, selected]);

  const onSelect = useCallback(
    (question: QuestionData, newValue: RadioGroupAnwserType) => {
      const answer = selected?.find(
        (e) => e.question_id === question.question_id
      );
      if (answer) {
        answer.answers = [
          {
            answer_id:
              question.possible_answers.find((e) => e.answer_name === newValue)
                ?.answer_id ?? '',
            answer_name: newValue,
            answer_display:
              question.possible_answers.find((e) => e.answer_name === newValue)
                ?.answer_display ?? '',
            type: 'checkbox',
            user_input: false,
          },
        ];
        setSelected([...(selected ?? [])]);
      } else {
        const newAnswer: AnswerData = {
          question_id: question.question_id,
          question_name: question.question_name,
          version: question.version,
          answers: [
            {
              answer_id:
                question.possible_answers.find(
                  (e) => e.answer_name === newValue
                )?.answer_id ?? '',
              answer_name: newValue,
              answer_display:
                question.possible_answers.find(
                  (e) => e.answer_name === newValue
                )?.answer_display ?? '',
              type: 'checkbox',
              user_input: false,
            },
          ],
        };
        setSelected([...(selected ?? []), newAnswer]);
      }
    },
    [selected]
  );

  return (
    <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
      <div className={style.question}>
        <div className={style.title}>
          <h1>{question.page.page_title}</h1>
          <h2
            dangerouslySetInnerHTML={{
              __html: question.page.page_display
                ? question.page.page_display
                : '',
            }}
          ></h2>
        </div>{' '}
        <div className={style.answers}>
          <div className={style.vertical}>
            <b>Social Outcome</b>
          </div>

          <div className={style.cobenefits}>
            <br></br>
            {Object.entries(cobenefits).map(([cobenefit]) => (
              <div className={style.cobenefit} key={cobenefit}>
                <img
                  src={CoBenefitIcons[cobenefit as CoBenefit] ?? CoBenefitIcons['Other']}
                  alt={cobenefit}
                />
                <p>{cobenefit}</p>
              </div>
            ))}
          </div>
          {Object.entries(pathways).map(([pathway, values]) => {
            return (
              <div className={style.answer} key={pathway}>
                <p>{pathway}</p>
                <br></br>
                {values.map((value, i) => {
                  return (
                    <div className={style.radioanswer} key={i}>
                      <RadioGroupAnswer
                        value={
                          selected?.find(
                            (e) => e.question_id === value.question_id
                          )?.answers[0]?.answer_name ?? ''
                        }
                        onChange={(v) => {
                          onSelect(value, v);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Question>
  );
};

export default MultiSelectRadioGroupQuestion;
