import React from 'react';

import style from './notinvited.module.scss';

const NotInvitedPage: React.FC = () => {
  return (
    <div className={style.page}>
      <h1>
      You are not invited to this survey.
      </h1>
    </div>
  );
};

export default NotInvitedPage;
