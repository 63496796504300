import React, { useState } from 'react';

import style from './tooltip.module.scss';

interface TooltipProp {
  text: string;
  children: React.ReactNode;
}

const Tooltip = ({ text, children }: TooltipProp) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className={style.tooltipContainer}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div
          className={style.tooltip}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        >
        </div>
      )}
    </div>
  );
};

export default Tooltip;
