import React from 'react';
import Question from '../../components/question/question';
import style from './thank-you-page.module.scss';
import { QuestionProps } from '../../types';

const ThankYouPage: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
}) => {
  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <p>{question.page.page_title}</p>
        <h1>{question.page.page_display}</h1>
        <div className={style.parent}>
          <div className={style.child_text}>
            Thank you!
          </div>
          <div className={style.child_image}>
            {/*<img src={infographics} />*/}
          </div>
        </div>

        <footer>
          To learn more about our broader research project and meet the team,
          see our <a href="https://www.mcgill.ca/naturalclimatesolutions/" target="_blank" rel="noreferrer">project website at McGill</a>
          <br></br>
          <br></br>
          To learn more about NCS in general,{' '}
          <a href="https://www.nature.org/en-us/what-we-do/our-priorities/tackle-climate-change/climate-change-stories/natural-climate-solutions-science/" target="_blank" rel="noreferrer">
            TNC’s NCS Resource Center
          </a>{' '}
          is a great place to start.
        </footer>
      </div>
    </Question>
  );
};

export default ThankYouPage;
