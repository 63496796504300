import React from 'react';
import Question from '../../components/question/question';
import style from './question-incentive-info.module.scss';
import { QuestionProps } from '../../types';

const IncentiveInfoPage: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
}) => {
  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <h1>{question.page.page_title}</h1>
        <div className={style.parent}>
          <div className={style.child_text}>
            Upon completion of the survey you can opt into a lottery to win up
            to $1000 USD, which you can either keep or donate to an
            organization.
          </div>
        </div>
      </div>
    </Question>
  );
};

export default IncentiveInfoPage;
