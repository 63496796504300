import { useEffect } from 'react';
import { NewQuestionInterface } from '../types';

const useQueryParams = (question: NewQuestionInterface | null) => {
  const url = window.location.href;

  // find survey_id in url
  const surveyId = new URL(url).searchParams.get('survey_id');

  useEffect(() => {
    // set question id query param
    if (question?.page.page_id) {
      const newUrl = new URL(url);
      newUrl.searchParams.set('questionId', question.page.page_id);
      window.history.pushState({}, '', newUrl.toString());
    }
  }, [question?.page.page_id, url]);

  return surveyId;
};

export default useQueryParams;
