import React from 'react';
import Question from '../../components/question/question';

import infographics from '../../assets/main/progress-page.webp';

import style from './progress-page.module.scss';
import { QuestionProps } from '../../types';
import Button from '../../components/button/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons/faCaretRight';

const MainPage: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
}) => {
  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <div className={style.parent}>
          <div className={style.buttons}>
            <p>Survey sections</p>
            <div className={style.tryOutMyLuckA}>
              <Button>Introduction and consent (1 minute)</Button>
            </div>
            <div className={style.RightArrowIcon}>
              <FontAwesomeIcon icon={faCaretRight} style={{color: '#778899ff',}} rotation={90}/>
            </div>
            <div className={style.tryOutMyLuckB}>
              <Button>Choice experiment (3 minutes)</Button>
            </div>
            <div className={style.RightArrowIcon}>
              <FontAwesomeIcon icon={faCaretRight} style={{color: '#778899ff',}} rotation={90}/>
            </div>
            <div className={style.tryOutMyLuckC}>
              <Button>Evidence validation (5 minutes)</Button>
            </div>
            <div className={style.RightArrowIcon}>
              <FontAwesomeIcon icon={faCaretRight} style={{color: '#778899ff',}} rotation={90}/>
            </div>
            <div className={style.tryOutMyLuckD}>
              <Button>Demographic information (1 minute)</Button>
            </div>
          </div>
          <div className={style.child_image}>
            <img src={infographics} />
          </div>
        </div>
      </div>
    </Question>
  );
};

export default MainPage;
