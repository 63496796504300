import {
  InvitationInterface,
  InvitationResponseInterface,
  LotteryInterface,
  NewAnswerInterface,
  NewQuestionInterface,
  PossibleAnswerType,
  QuestionInterface,
  SurverResponse,
} from '../../types';
import { env } from '../env';
import { client } from './client';
import freetextquestion from '../../__mock_data__/question-freetext.json';
import consentquestion from '../../__mock_data__/question-consent.json';
import radioquestion from '../../__mock_data__/question-radio.json';
import multiselectquestion from '../../__mock_data__/question-multiselect.json';
import mapquestion from '../../__mock_data__/question-map.json';
import radiogroupquestion from '../../__mock_data__/question-radiogroup.json';
import multiselectradiogroupquestion from '../../__mock_data__/question-multiselectradiogroup.json';
import multiselectwithpicturequestion from '../../__mock_data__/question-multiselectwithpicture.json';
import multiselectwithBiomquestion from '../../__mock_data__/question-multiselectwithbiom.json';
import surveyparticipationquestion from '../../__mock_data__/question-surveyparticipation.json';
import regionalexpertisequestion from '../../__mock_data__/question-regionalexpertise.json';
import ncsconstraintsquestion from '../../__mock_data__/question-ncsconstraints.json';
import submitquestion from '../../__mock_data__/question-submit.json';
import main from '../../__mock_data__/main-page.json';
import selecting_good_options from '../../__mock_data__/question-selecting-good-options.json';
import radio from '../../__mock_data__/question-radio-without-freetext.json';
import lottery from '../../__mock_data__/lottery.json';
import evicence_cobenefit from '../../__mock_data__/question-radio-evicende-cobenefit.json';
import evidence_map from '../../__mock_data__/evidence-map.json';
import another_area from '../../__mock_data__/another-area.json';

import example from '../../__mock_data__/example-question.json';

import * as Types from '../../types';

export const API_ERROR = 'SOMETHING WENT WRONG';

const version = 'v1';

const questions = [
  main,
  freetextquestion,
  consentquestion,
  radioquestion,
  multiselectquestion,
  mapquestion,
  radiogroupquestion,
  multiselectradiogroupquestion,
  multiselectwithpicturequestion,
  surveyparticipationquestion,
  multiselectwithBiomquestion,
  submitquestion,
  regionalexpertisequestion,
  ncsconstraintsquestion,
  selecting_good_options,
  lottery,
  radio,
  evicence_cobenefit,
  evidence_map,
  another_area,
];

export const begin = (survey_id: string): Promise<SurverResponse> => {
  return new Promise((resolve, reject) => {
    client
      .get(`authenticate/${survey_id}`, {
        headers: {
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET',
          'Access-Control-Allow-Headers': 'application/json',
        },
      })
      .json<SurverResponse>()
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const getCurrentPage = (): Promise<NewQuestionInterface> => {
  return new Promise((resolve, reject) => {
    client
      .get('current')
      .json<NewQuestionInterface>()
      .then((page) => {
        resolve(page);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const getAdministrativeBoundaries = (country: string, controller: AbortController | null, states?: string[]):
 Promise<Types.AdministrativeBoundaries> => {
  
  return new Promise((resolve, reject) => {
    client
      .post(`boundaries/${country}`, {
        signal: controller?.signal,
        json: { states: states ?? [] },
      })
      .json<Types.AdministrativeBoundaries>()
      .then((boundaries) => {
        if (boundaries.boundaries) {
          resolve({
            boundaries: boundaries.boundaries.map((boundary) => ({
              ...boundary,
              country
            })),
          });
        }else {
          resolve({
            boundaries: [],
          });
        }
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const participate = (
  {
    mail,
    user_name,
    organization,
  }: InvitationInterface
): Promise<{ status: string; env: string; msg: string }> => {
  return new Promise((resolve, reject) => {
    client
      .post('participate', { json: { mail, user_name, organization } })
      .json<{ status: string; env: string; msg: string }>()
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const getQuestion = (
  question_id: number
): Promise<QuestionInterface<PossibleAnswerType>> => {
  if (env.environment === 'test') {
    return new Promise((resolve) => {
      resolve(
        (questions.find(
          (e) => e.id === question_id
        ) as QuestionInterface<PossibleAnswerType>) ?? 'No question found'
      );
    });
  }
  return new Promise((resolve, reject) => {
    client
      .get(`${version}/question/${question_id}`)
      .json()
      .then((question) => {
        resolve(question as QuestionInterface<PossibleAnswerType>);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const getQuestionV2 = (): Promise<NewQuestionInterface> => {
  return new Promise((resolve) => {
    resolve(example as NewQuestionInterface);
  });
};

export const back = (): Promise<NewQuestionInterface> => {
  return new Promise((resolve, reject) => {
    client
      .get('back')
      .json<NewQuestionInterface>()
      .then((page) => {
        resolve(page);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const postAnswer = (answer: NewAnswerInterface): Promise<NewQuestionInterface> => {
  return new Promise((resolve, reject) => {
    client
      .post('next', { json: answer })
      .json<NewQuestionInterface>()
      .then((page) => {
        resolve(page);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const invite = async (data: InvitationInterface): Promise<InvitationResponseInterface> => {
  return new Promise((resolve, reject) => {
    client
      .post('invite-user', { json: data })
      .json<InvitationResponseInterface>()
      .then((page) => {
        resolve(page);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const submit = async (): Promise<NewQuestionInterface> => {
  return new Promise((resolve, reject) => {
    client
      .patch('submit')
      .json<NewQuestionInterface>()
      .then((page) => {
        resolve(page);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};

export const submitLotteryMail = async (data: LotteryInterface): Promise<NewQuestionInterface> => {
  return new Promise((resolve, reject) => {
    client
      .post('lottery-mail', {json: data })
      .json<NewQuestionInterface>()
      .then((page) => {
        resolve(page);
      })
      .catch((e) => {
        reject(`${API_ERROR}: ${e}`);
      });
  });
};
