import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../button/button';

import style from './next-button.module.scss';

interface NextButtonProps {
    onClick: () => void;
    disabled?: boolean;
    label?: string;
}

const NextButton = ({ onClick, disabled, label }: NextButtonProps): JSX.Element => {
  return (
    <div className={label === 'Enter lottery!' ? style.enterLotteryButton :
      label === 'Enter' ? style.enterSurveyButton : style.nextButton}>
      <Button disabled={disabled} onClick={onClick}>
        <p>{label ? label : 'Next'}</p> {
          label !== 'Enter' &&
          <FontAwesomeIcon icon={faArrowRight} />
        }
      </Button>
    </div>
  );
};

export default NextButton;
