/* eslint-disable @typescript-eslint/no-explicit-any */
import { LatLngExpression } from 'leaflet';
import React, { useCallback, useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';

import style from './region.module.scss';
import { getCenter, getZoom } from '../../pages/util';

import { getAdministrativeBoundaries } from '../../api/backend/api';

const MAP_URL = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png';

import * as Types from '../../types';

interface RegionProps {
  selected_region?: {
    [key: string]: string[];
  } | number[][][];
}

const Region = ({ selected_region }: RegionProps) => {
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState<LatLngExpression>();

  const [selectedRegions, setSelectedRegions] = useState<Types.GeoJSON[]>();

  const getRegions = useCallback(async () => {
    if (!selected_region) return;
    const countries = Object.keys(selected_region);

    const regions = await Promise.all(countries.map(async (country) => {
      return await getAdministrativeBoundaries(country, null, (selected_region as any)[country]);
    }));

    const geojson = regions.map((region) => {
      return region.boundaries.map((boundary) => {
        return boundary.geometry;
      });
    });
    
    return geojson.flat();

  }, [selected_region]);

  useEffect(() => {
    if ((selected_region as number[][][])?.length > 0) {
      const merged = {
        type: 'Polygon',
        coordinates: (selected_region as any) as LatLngExpression[][][],
      } as Types.GeoJSON;
      const _center = getCenter(merged.coordinates as any);
      setCenter([_center[1], _center[0]]);
      const _zoom = getZoom(merged.coordinates as any);
      setZoom(_zoom);
      setSelectedRegions([merged]);
      return;
    }
    getRegions().then((geojson) => {
      if (!geojson) return;
      setSelectedRegions(geojson);

      const merged = geojson.reduce((acc, curr) => {
        return {
          type: 'MultiPolygon',
          coordinates: [...acc.coordinates, ...curr.coordinates],
        };
      });
      const _center = getCenter(merged.coordinates as any);
      setCenter([_center[1], _center[0]]);
      const _zoom = getZoom(merged.coordinates as any);
      setZoom(_zoom);
    });

  }, [getRegions, selected_region]);

  if (!selected_region || !center) return null;
  return (
    <div className={style.region}>
      <p>Region Selected</p>
      <div className={style.map}>
        <MapContainer
          style={{ height: '100%' }}
          center={center}
          zoom={zoom}
          scrollWheelZoom={true}
        >
          <TileLayer url={MAP_URL} />
          {
            selectedRegions?.map((e, i) => {
              return <GeoJSON
                key={i}
                style={{ fillColor: '#88aa88', color: '#004400' }}
                data={e}
              />;
            })
          }
        </MapContainer>
      </div>
    </div>
  );
};

export default Region;
