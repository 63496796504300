import React, { useEffect, useState } from 'react';
import FreeTextAnswer from '../../components/possible-answer/free-text-answer/free-text-answer';
import RadioAnswer from '../../components/possible-answer/radio-answer/radio-answer';
import Question from '../../components/question/question';
import { QuestionProps } from '../../types';
import style from './question-radio.module.scss';

const RadioQuestion: React.FC<QuestionProps> = ({
  question,
  currentQuestion,
  totalQuestions,
  answerQuestion,
}) => {
  const [selected, setSelected] = useState<string | undefined>(
    question.previous_answer?.answers[0].answers[0].answer_id
  );

  const [freeText, setFreeText] = useState<string>(
    question.previous_answer?.answers[0].answers.find((e) => e.user_input)
      ?.answer_display ?? ''
  );

  const ncsArray = question.page.page_helper?.split(',');

  useEffect(() => {
    const answer = question.page.questions[0].possible_answers?.find(
      (e) => e.answer_id === selected
    );
    if (answer?.user_input) {
      answer.answer_display = freeText;
    }
    answerQuestion(
      question.page.questions[0].question_id,
      answer ? [answer] : []
    );
  }, [answerQuestion, freeText, question.page.questions, selected]);

  return (
    <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
      <div className={style.radio}>
        <div className={style.title}>
          <h1>
            {question.page.page_title ??
              question.page.questions[0].question_display}
          </h1>
          {question.page.page_name === 'regional_expertise' ? (
            <div>
              <h2>
                <ul>
                  {ncsArray?.map((topic, index) => (
                    <li key={index}>{topic.trim()}</li>
                  ))}
                </ul>
              </h2>
              <br></br>
              <br></br>
              <br></br>
              <h1>{question.page.questions[0].question_display}</h1>
            </div>
          ) : (
            <h2 className={style.subtitle}>
              {question.page.page_display ??
                question.page.questions[0].question_helper}
            </h2>
          )}
        </div>{' '}
        <div className={style.answers}>
          {question.page.questions[0].possible_answers?.map(
            (possibleAnswer, i) => {
              return (
                <div key={i}>
                  {!possibleAnswer.user_input ? (
                    <>
                      <RadioAnswer
                        selected={possibleAnswer.answer_id === selected}
                        onSelect={() => setSelected(possibleAnswer.answer_id)}
                        possibleAnswer={possibleAnswer}
                      />
                    </>
                  ) : (
                    <>
                      <FreeTextAnswer
                        onClick={() => setSelected(possibleAnswer.answer_id)}
                        selected={possibleAnswer.answer_id === selected}
                        possibleAnswer={possibleAnswer}
                        value={freeText}
                        onChange={(e) => setFreeText(e.target.value)}
                      />
                    </>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
    </Question>
  );
};

export default RadioQuestion;
