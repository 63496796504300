import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import style from './radiogroup-answer.module.scss';

export type RadioGroupAnwserType = 'negative' | 'neutral' | 'positive' | '';

interface RadioGroupAnswerProps {
  onChange: (value: RadioGroupAnwserType) => void;
  value: string;
  deselectable?: boolean;
}

const RadioGroupAnswer = ({
  onChange,
  value,
  deselectable = false,
}: RadioGroupAnswerProps) => {

  const onRadioSelect = (option: RadioGroupAnwserType) => {
    if (deselectable) {
      onChange(value === '' ? option : '');
    }else {
      onChange(option);
    }
  };

  return <div className={style.radiogroup}>
    <div onClick={() => {
      onRadioSelect('positive');
    }} className={`${style.radio} ${style.positive} ${value === 'positive' ? style.selected : ''}`}>
      <FontAwesomeIcon icon={faPlus} />
    </div>
    <div onClick={() => {
      onRadioSelect('negative');
    }} className={`${style.radio} ${style.negative} ${value === 'negative' ? style.selected : ''}`}>
      <FontAwesomeIcon icon={faMinus} />
    </div>
    <div onClick={() => {
      onRadioSelect('neutral');
    }} className={`${style.radio} ${value === 'neutral' ? style.selected : ''}`}>
      <FontAwesomeIcon icon={faCircle} />
    </div>
  </div>;
};

export default RadioGroupAnswer;
