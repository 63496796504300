import React from 'react';
import style from './multi-select-answer.module.scss';
import { BiomIcons, CoBenefitIcons } from '../../../pages/util';
import { Biom, CoBenefit } from '../../../types';

interface MultiSelectAnswerProps {
    answer_id: string;
    answer_text: string;
    selected?: boolean;
    user_input?: boolean;
    onClick?: () => void;
    onInput?: (value: string) => void;
    value?: string;
}

const MultiSelectAnswer = ({ answer_text, selected, user_input, value, onClick, onInput }: MultiSelectAnswerProps) => {
  return (<div className={`${style.answer} ${selected ? style.selected : ''}`} onClick={(e) => {
    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
    onClick && onClick();
  }}>
    <img
      src={
        CoBenefitIcons[answer_text as CoBenefit] ||
                    BiomIcons[answer_text as Biom]
      }
      alt={`${answer_text}-icon`}
    />
    <p>{answer_text}</p>
    {user_input && <input type="text" placeholder="Please specify" onClick={e=>e.stopPropagation()} value={value} onChange={(e) => {
      onInput && onInput(e.target.value);
    }} />}
  </div>);
};

export default MultiSelectAnswer;
