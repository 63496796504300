import React, { useState } from 'react';
import Button from '../../components/button/button';
import Input from '../../components/input/input';
import Question from '../../components/question/question';
import style from './submit-page.module.scss';
import { QuestionProps } from '../../types';
import { invite } from '../../api/backend/api';
import { InvitationInterface } from '../../types';
import useSurveyStore from '../../store/survey.store';
import { validateEmail } from '../util';

const SubmitPage: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
}) => {
  const { setAlert, submit, back } = useSurveyStore();
  const [uname, setUname] = useState<string>('');
  const [org, setOrg] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const onInvite = async () => {
    if (validateEmail(email)) {
      const body_data:InvitationInterface = {
        mail: email,
        user_name: uname,
        organization: org,
      };

      const response = await invite(body_data);

      setAlert({
        message: response.msg,
        type: response.status == 'success' ? 'info' : 'warning',
      });

      setEmail('');
      setOrg('');
      setUname('');
    } else {
      setAlert({
        message: 'Not a valid email address!',
        type: 'warning',
      });
    }
  };

  const onSubmit = () => {
    submit();
  };

  const handleBack = () => {
    back();
  };

  return (
    <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
      <div className={style.submit}>
        <div className={style.sidebar}>
          <p>
            It is critical we understand these issues from a range of
            perspectives, locations, and backgrounds.{' '}
            <b>
              Is there anyone else that would be appropriate to take this
              survey?{' '}
            </b>
            <br />
            Please add their contact information. Your submission will be kept
            anonymous. Thank you for your nomination!
          </p>
          <div className={style.buttons}>
            <Input
              placeholder="Name"
              onChange={(e) => {
                //updateUserName(e.target.value);
                setUname(e.target.value);
              }}
              value={uname}
            />

            <Input
              placeholder="Organization"
              onChange={(e) => {
                //updateOrganization(e.target.value);
                setOrg(e.target.value);
              }}
              value={org}
            />

            <Input
              placeholder="E-mail address"
              onChange={(e) => {
                //updateMail(e.target.value);
                setEmail(e.target.value);
              }}
              value={email}
            />

            <div className={style.submit}>
              <Button onClick={onInvite}>Invite</Button>
            </div>
          </div>
        </div>
        <div className={style.container}>
          <h1>You have completed the survey!</h1>
          <p>
            Please press “<b>Submit</b>” if you are comfortable with your answer, 
            or “<b>Back</b>” if you would like to review anything.
          </p>
          <p>
            After submitting you will have the chance to enter the lottery for 
            an Amazon gift card.
          </p>
          <div className={style.buttons}>
            <div className={style.goBack}>
              <Button onClick={handleBack}>Back</Button>
            </div>
            <div className={style.submit}>
              <Button onClick={onSubmit}>Submit</Button>
            </div>
          </div>
        </div>
      </div>
    </Question>
  );
};

export default SubmitPage;
