import React from 'react';
import Question from '../../components/question/question';
import style from './thank-you-ending-page.module.scss';
import { QuestionProps } from '../../types';
import ending_picture from '../../assets/main/end_picture.webp';

const ThankYouEndingPage: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
}) => {
  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <p>{question.page.page_title}</p>
        <h1>{question.page.page_display}</h1>
        <div className={style.parent}>
          <div className={style.child_text}>
            Thank you again for your participation! <br></br>
            We will contact you through email if you win.
          </div>
          <div className={style.child_image}>
            <img src={ending_picture} />
          </div>
        </div>
      </div>
    </Question>
  );
};

export default ThankYouEndingPage;
