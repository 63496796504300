import { faCheck, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import style from './checkbox_old.module.scss';

interface CheckboxProps {
  onChange: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  hasInput?: boolean;
  inputPlaceholder?: string;
  input?: string;
  onInput?: (e: string) => void;
  showLabel?: boolean;
}

const CheckboxOld: React.FC<CheckboxProps> = ({
  onChange,
  checked,
  disabled,
  label,
  hasInput,
  inputPlaceholder,
  input,
  onInput,
  showLabel,
}: CheckboxProps) => {
  return (
    <div
      className={`${style.checkbox} ${checked ? style.checked : ''} ${
        disabled ? style.disabled : ''
      }`}
      onClick={() => {
        if (!disabled) onChange(!checked);
      }}
    >
      <FontAwesomeIcon icon={checked ? faCheck : faSquare} />
      {(showLabel == true || showLabel == undefined) && <p>{label}</p>}
      {hasInput && (
        <input
          placeholder={inputPlaceholder}
          value={input}
          onChange={(e) => {
            if (onInput) onInput(e.target.value);
          }}
        />
      )}
    </div>
  );
};

export default CheckboxOld;
