import React from 'react';

import style from './card.module.scss';

interface CardProps {
  children?: React.ReactNode;
}

const Card = ({
  children,
}: CardProps): JSX.Element => {
  return <div className={style.card}>
    {children}
  </div>;
};

export default Card;
