import React from 'react';
import style from './question.module.scss';
import mcgill from '../../assets/McGill_University.png';

interface QuestionProps {
  children: JSX.Element;
  totalQuestions: number;
  currentQuestion: number;
  showQuestionNumber?: boolean;
  hasPadding?: boolean;
  maxHeight?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

const Question = React.forwardRef<HTMLDivElement, QuestionProps>(
  ({ children, maxHeight = false, showQuestionNumber = true, hasPadding = true }, ref) => {
    return (
      <div ref={ref} className={`${style.question} ${maxHeight ? style.maxHeight : ''}`}>
        {showQuestionNumber && <h1>
          {' '}
        </h1>}
        <div className={`${style.card} ${hasPadding ? style.padding : ''}`}>
          <img className={style.logo} src={mcgill} alt="mcgill-logo" />
          {children}
        </div>
      </div>
    );
  }
);

Question.displayName = 'Question';

export default Question;
