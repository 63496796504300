import React, { useCallback, useEffect, useState } from 'react';
import Question from '../../components/question/question';

import style from './question-evidence-map.module.scss';
import { NewPossibleAnswer, QuestionProps } from '../../types';
import Checkbox from '../../components/checkbox-old/checkbox_old';
import EvidenceMapComponent from '../../components/evidence-map/evidence-map';
import colorbar from '../../assets/colorbar.png';

const EvidenceMap: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answerQuestion,
}) => {
  const [answer, setAnswer] = useState<string>(
    question.previous_answer?.answers[0].answers[0].answer_id ?? ''
  );

  const onClick = useCallback((answer_id: string) => {
    setAnswer(answer_id);
  }, []);

  useEffect(() => {
    const _answer = question.page.questions[0].possible_answers.find(
      (e) => e.answer_id === answer
    );
    if (_answer)
      answerQuestion(question.page.questions[0].question_id, [
        _answer as NewPossibleAnswer,
      ]);
  }, [answer, answerQuestion, question.page.questions]);

  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <h2
          dangerouslySetInnerHTML={{
            __html: question.page.page_display
              ? question.page.page_display
              : '',
          }}
        ></h2>
        <div className={style.parent}>
          <div className={style.child_image}>
            <p>
              <b>{question.page.page_helper}</b>
            </p>
            <EvidenceMapComponent
              evidence_map={question.page.extra_data?.evidence_map}
              selected_region={question.page.extra_data?.selected_region}
            />
          </div>
          <div className={style.child_text}>
            <span>{question.page.questions[0].question_display}</span>
            <div className={style.answers}>
              {question.page.questions[0].possible_answers.map((e, i) => {
                return (
                  <div key={i} className={style.flex}>
                    <Checkbox
                      onChange={() => onClick(e.answer_id)}
                      checked={answer === e.answer_id}
                      label={e.answer_display}
                    ></Checkbox>
                  </div>
                );
              })}
            </div>
            <div className={style.colorbar}>
              <div className={style.left}>
                <img src={colorbar}></img>
              </div>
              <div className={style.right}>
                <div className={style.high}>
                  <span>High evidence</span>
                </div>
                <div className={style.low}>
                  <span>Low evidence</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Question>
  );
};

export default EvidenceMap;
