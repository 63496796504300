import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Question from '../question/question';
import style from './loading.module.scss';

interface LoadingProps {
    currentQuestion: number;
    totalQuestions: number;
}

const Loading = ({
  currentQuestion,
  totalQuestions,
}: LoadingProps): JSX.Element => {
  return <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
    <div className={style.loading}>
      <FontAwesomeIcon icon={faSpinner} spin size='xl' />
    </div>
  </Question>;
};

export default Loading;
