import React from 'react';
import style from './progress-bar.module.scss';

import Button from '../../components/button/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons/faCaretRight';

interface ProgressBarProps {
  progress?: number;
  c1: string;
  c2: string;
  c3: string;
  c4: string;
  c5: string;
  c6: string;
  c7: string
}

const ProgressBar = ({ c1, c2, c3, c4, c5, c6, c7}: ProgressBarProps) => {

  return (
    <div className={style.container}>
      <div className={style.tryOutMyLuckA}>
        <Button stylecolor={{background: c1,}}>Intro</Button>
      </div>
      <div className={style.RightArrowIcon}>
        <FontAwesomeIcon icon={faCaretRight} style={{color: c5,}} />
      </div>
      <div className={style.tryOutMyLuckB}>
        <Button stylecolor={{background: c2,}}>Choice experiment</Button>
      </div>
      <div className={style.RightArrowIcon}>
        <FontAwesomeIcon icon={faCaretRight} style={{color: c6,}} />
      </div>
      <div className={style.tryOutMyLuckC}>
        <Button stylecolor={{background: c3,}}>Evidence validation</Button>
      </div>
      <div className={style.RightArrowIcon}>
        <FontAwesomeIcon icon={faCaretRight} style={{color: c7,}} />
      </div>
      <div className={style.tryOutMyLuckD}>
        <Button stylecolor={{background: c4,}}>Demographic information</Button>
      </div>
    </div>
  );
};

// const ProgressBar = ({ progress }: ProgressBarProps) => {
//   return (
//     <div className={style.container}>
//       <div className={style.progressBar}>
//         <div className={style.progress} style={{ width: `${progress}%` }}></div>
//       </div>
//     </div>
//   );
// };

export default ProgressBar;
