/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
  CircleMarker,
  GeoJSON,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMap,
  useMapEvents,
  ZoomControl,
} from 'react-leaflet';
import { LatLng } from 'leaflet';

import * as Types from '../../types';

import style from './map.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faDrawPolygon,
  faEarthAmerica,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { getCountry, getHoveredBoundary } from '../../pages/util';
import Tooltip from '../tooltip/tooltip';
import { getAdministrativeBoundaries } from '../../api/backend/api';
import useSurveyStore from '../../store/survey.store';

import * as L from 'leaflet';
import polygon_icon from '../../assets/map/polygon.webp';
import cursor_icon from '../../assets/map/cursor.webp';

const REQUEST_WAIT = 300;
const CONNECT_THRESHOLD = 10;

const MAP_URL =
  'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png';

interface MapComponentProps {
  onCountrySelected: (country: Types.GeoJSON | null) => void;
  onBoundariesSelected: (
    au: {
      country: string;
      name: string;
      geometry: Types.GeoJSON;
    }[]
  ) => void;
  onFreeSelectStateChange: (state: boolean) => void;
  selected: Types.GeoJSON | null;
  freeSelect: boolean;
  selectedBoundaries:
    | {
        name: string;
        country: string;
        geometry: Types.GeoJSON;
      }[]
    | null;
}

const LeafletMapComponent: React.FC<MapComponentProps> = ({
  onCountrySelected,
  onBoundariesSelected,
  onFreeSelectStateChange,
  selected,
  freeSelect,
  selectedBoundaries,
}) => {
  const [updating, setUpdating] = useState(false);

  const [freeSelection, setFreeSelection] = useState<LatLng[] | null>(null);
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null);
  const [drawConnect, setDrawConnect] = useState<boolean>(false);

  const mapRef = React.useRef<HTMLDivElement>(null);

  const { setAlert } = useSurveyStore();

  const [boundaries, setBoundaries] = useState<
    | {
        name: string;
        country: string;
        geometry: Types.GeoJSON;
      }[]
    | null
  >(null);
  const [hoveredBoundary, setHoveredBoundary] = useState<Types.GeoJSON | null>(
    null
  );

  const timer = React.useRef<NodeJS.Timeout | null>(null);
  const map = useMap();

  useEffect(() => {
    if (hoveredCountry) {
      const search = async () => {
        if (timer.current) clearTimeout(timer.current);

        timer.current = setTimeout(async () => {
          await getAdministrativeBoundaries(hoveredCountry, null).then(
            (data) => {
              if (data) setBoundaries(data.boundaries);
            }
          );
        }, REQUEST_WAIT);
      };

      search();
    }
  }, [hoveredCountry]);

  useMapEvents({
    contextmenu: () => {
      onClose();
    },

    click: (e) => {
      if (selected && freeSelect) return;
      if (freeSelect) {
        if (!freeSelection) {
          setFreeSelection([e.latlng]);
          onCountrySelected(null);
        } else {
          if (
            map.latLngToLayerPoint(freeSelection[0]).distanceTo(e.layerPoint) <
              CONNECT_THRESHOLD &&
            freeSelection.length > 2
          ) {
            setFreeSelection(null);
            onCountrySelected({
              type: 'Polygon',
              coordinates: [freeSelection.map((e) => [e.lng, e.lat]) as any],
            });
          } else {
            setFreeSelection([...freeSelection, e.latlng]);
          }
        }
        return;
      }
      getCountry(e.latlng.lat, e.latlng.lng).then((data) => {
        if (data) onCountrySelected(data.data[0]?.geojson);
      });
      getHoveredBoundary(boundaries as any, e.latlng.lat, e.latlng.lng).then(
        (data) => {
          if (data) {
            let newBoundaries = selectedBoundaries
              ? [...selectedBoundaries]
              : [];

            if (newBoundaries.find((b) => b.name === data.name)) {
              newBoundaries = newBoundaries.filter((b) => b.name !== data.name);
            } else {
              newBoundaries.push(data);
            }
            if (newBoundaries.length > 1) {
              setAlert({
                type: 'info',
                message: `You can only select one administrative unit. 
                If you want to select multiple, use the free selection tool 
                (bottom right corner). To unselect, click on the same polygon 
                again or right click anywhere on the map!`,
              });
              return;
            }
            onBoundariesSelected(newBoundaries);
          }
        }
      );
    },

    mousemove: (e) => {
      if (freeSelect && freeSelection && freeSelection.length > 2) {
        if (
          map.latLngToLayerPoint(freeSelection[0]).distanceTo(e.layerPoint) <
          CONNECT_THRESHOLD
        ) {
          setDrawConnect(true);
        } else {
          setDrawConnect(false);
        }
        return;
      }
      if (freeSelect) return;
      if (updating) return;

      setUpdating(true);

      setTimeout(() => {
        setUpdating(false);
      }, 500);

      getCountry(e.latlng.lat, e.latlng.lng).then((data) => {
        setUpdating(false);
        if (data) {
          setHoveredCountry(data.country);
        } else {
          setBoundaries(null);
          setHoveredCountry(null);
          setHoveredBoundary(null);
        }
      });
      if (boundaries)
        getHoveredBoundary(boundaries as any, e.latlng.lat, e.latlng.lng).then(
          (data) => {
            if (data) {
              setHoveredBoundary(data.geometry);
            }
          }
        );
    },
  });

  const onFreeSelectionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onFreeSelectStateChange(false);
      setFreeSelection(null);
      onCountrySelected(null);
      onBoundariesSelected([]);
    },
    [onBoundariesSelected, onCountrySelected, onFreeSelectStateChange]
  );

  const onCountrySelectionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onFreeSelectStateChange(true);
      onCountrySelected(null);
      setFreeSelection(null);
    },
    [onCountrySelected, onFreeSelectStateChange]
  );

  const onClose = useCallback(() => {
    onCountrySelected(null);
    setFreeSelection(null);
    setDrawConnect(false);
    onBoundariesSelected([]);
  }, [onBoundariesSelected, onCountrySelected]);

  const onAccept = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      if (selected) {
        return;
      }
      if (!freeSelection || freeSelection.length <= 3) {
        if (freeSelection && freeSelection.length > 0)
          setFreeSelection(freeSelection);

        return;
      }
      if (freeSelection)
        onCountrySelected({
          type: 'Polygon',
          coordinates: [freeSelection.map((e) => [e.lng, e.lat]) as any],
        });
      setFreeSelection(null);
    },
    [freeSelection, onCountrySelected, selected]
  );

  const renderControls = useCallback(() => {
    return (
      <div id="overlay" className={style.selectionMode}>
        <div className={style.buttons}>
          <Tooltip text="Free selection">
            <button
              className={freeSelect ? style.active : ''}
              onClick={onCountrySelectionClick}
            >
              <img className={style.map_icon} src={polygon_icon} />
            </button>
          </Tooltip>
          <div className={style.separator} />
          <Tooltip text="Select an administrative area">
            <button
              className={!freeSelect ? style.active : ''}
              onClick={onFreeSelectionClick}
            >
              <img className={style.map_icon} src={cursor_icon} />
            </button>
          </Tooltip>
        </div>
        <div>
          {freeSelect && (
            <div className={style.freeSelectOptions}>
              <button onClick={onClose} className={style.close}>
                <FontAwesomeIcon className={style.map_icon_sub} icon={faX} />
              </button>
              <button
                onClick={onAccept}
                className={`${style.accept} ${
                  (!freeSelection || freeSelection.length < 3) && style.disabled
                }`}
              >
                <FontAwesomeIcon className={style.map_icon_sub} icon={faCheck} />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }, [
    freeSelect,
    freeSelection,
    onAccept,
    onClose,
    onCountrySelectionClick,
    onFreeSelectionClick,
  ]);

  const drawFreeSelection = useCallback(() => {
    return (
      <div>
        {freeSelection && (
          <Polyline pathOptions={{ color: 'red' }} positions={freeSelection} />
        )}
        {freeSelection && (
          <Marker position={freeSelection[freeSelection.length - 1]} />
        )}
        {selected && (
          <GeoJSON
            style={{ fillColor: '#004400', color: '#88aa88' }}
            data={selected}
          />
        )}
      </div>
    );
  }, [freeSelection, selected]);

  const drawBoundaries = useCallback(() => {
    return (
      <div>
        {boundaries?.map((e, i) => (
          <GeoJSON
            key={`${JSON.stringify(e)}-${i}`}
            data={e.geometry}
            style={{ fillColor: '#440000', color: '#aa8888' }}
          />
        ))}

        {hoveredBoundary && (
          <GeoJSON
            key={`${JSON.stringify(hoveredBoundary)}-hovered`}
            data={hoveredBoundary}
            style={{ fillColor: '#004400', color: '#88aa88' }}
          />
        )}

        {selectedBoundaries?.map((e, i) => (
          <GeoJSON
            key={`${JSON.stringify(e)}-${i}`}
            data={e.geometry}
            style={{ fillColor: '#004400', color: '#88aa88' }}
          />
        ))}
      </div>
    );
  }, [boundaries, hoveredBoundary, selectedBoundaries]);

  useEffect(() => {
    const container = document.querySelector('.leaflet-container');

    const event = (e: any) => {
      if (!container?.contains(e.target)) {
        setBoundaries(null);
        setHoveredCountry(null);
        setHoveredBoundary(null);
      }
    };

    if (container) {
      window.addEventListener('mousemove', event);
    }

    return () => {
      window.removeEventListener('mousemove', event);
    };
  }, []);

  return (
    <div id="map" ref={mapRef} className={style.map}>
      {renderControls()}
      {drawConnect && freeSelection && (
        <CircleMarker center={freeSelection[0]} radius={10} />
      )}
      {freeSelect && drawFreeSelection()}
      {!freeSelect && drawBoundaries()}
    </div>
  );
};

const MapComponent: React.FC<MapComponentProps> = ({
  onCountrySelected,
  onBoundariesSelected,
  onFreeSelectStateChange,
  selected,
  freeSelect,
  selectedBoundaries,
}) => {
  const div = L.DomUtil.get('overlay');
  if (div) {
    L.DomEvent.disableClickPropagation(div);
    L.DomEvent.disableScrollPropagation(div);
  }

  return (
    <MapContainer
      zoomControl={false}
      style={{ height: '100%', width: '100%' }}
      minZoom={3}
      maxBounds={[
        [-95, -185],
        [95, 185],
      ]}
      center={[51.505, -0.09]}
      zoom={3}
      scrollWheelZoom={true}
    >
      <TileLayer url={MAP_URL} />
      <ZoomControl position="bottomright" />
      <LeafletMapComponent
        selected={selected}
        selectedBoundaries={selectedBoundaries}
        freeSelect={freeSelect}
        onFreeSelectStateChange={onFreeSelectStateChange}
        onBoundariesSelected={onBoundariesSelected}
        onCountrySelected={onCountrySelected}
      />
    </MapContainer>
  );
};

export default MapComponent;
