import React, { useCallback, useEffect, useState } from 'react';
import MultiSelectAnswer from '../../components/possible-answer/multiselect-answer/multi-select-answer';
import Question from '../../components/question/question';
import useSurveyStore from '../../store/survey.store';
import { NewPossibleAnswer, QuestionProps } from '../../types';

import style from './question-multi-select.module.scss';

const MultiSelectQuestion: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answerQuestion,
}) => {
  const [selected, setSelected] = useState<string[]>(
    question.previous_answer?.answers[0].answers.map((e) => e.answer_id) ?? []
  );

  const [freeTextAnswer, setFreeTextAnswer] = useState<string>(question.previous_answer?.answers[0].answers.find(e=>e.user_input)?.answer_display === 'Other' ? '' :
    question.previous_answer?.answers[0].answers.find(e=>e.user_input)?.answer_display ?? '');

  const { setAlert } = useSurveyStore();

  useEffect(() => {
    answerQuestion(
      question.page.questions[0].question_id,
      question.page.questions[0]?.possible_answers.map((e: NewPossibleAnswer)=>{
        return {
          ...e,
          answer_display: e.user_input ? (freeTextAnswer.length > 0 ? freeTextAnswer : 'Other') : e.answer_display,
        };
      }).filter((e) =>
        selected.includes(e.answer_id)
      ) ?? []
    );
  }, [answerQuestion, freeTextAnswer, question.page.questions, selected]);

  const onAnswerClick = useCallback(
    (id: string) => {
      if (selected.includes(id)) {
        setSelected(selected.filter((item) => item !== id));
      } else {
        if (selected.length < 3) setSelected([...selected, id]);
        else
          setAlert({
            type: 'warning',
            message: 'You can only select 3 answers',
          });
      }
    },
    [selected, setAlert]
  );

  const onInput = useCallback(
    (answer_id: string, e: string) => {
      if (e.length > 0) {
        if (!selected.includes(answer_id)) setSelected([...selected, answer_id]);
      }
      setFreeTextAnswer(e);
    },
    [selected]
  );

  return (
    <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
      <div className={style.question}>
        <div className={style.title}>
          <h1 className={style.multiSelectTitle}>
            {question.page.page_title ??
              question.page.questions[0].question_display}
          </h1>
          <h2
            dangerouslySetInnerHTML={{
              __html:
                question.page.page_display ??
                question.page.questions[0].question_helper,
            }}
          ></h2>
        </div>{' '}
        <div className={style.answers}>
          {question.page.questions[0]?.possible_answers.map(
            (possibleAnswer, index) => {
              return (
                <div key={index}>
                  <MultiSelectAnswer
                    selected={selected.includes(possibleAnswer.answer_id)}
                    onClick={() => onAnswerClick(possibleAnswer.answer_id)}
                    answer_id={possibleAnswer.answer_id}
                    user_input={possibleAnswer.user_input}
                    answer_text={possibleAnswer.user_input ? 'Other' : possibleAnswer.answer_display}
                    onInput={(e) => onInput(possibleAnswer.answer_id, e)}
                    value={freeTextAnswer}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
    </Question>
  );
};

export default MultiSelectQuestion;
