/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { QuestionProps } from '../../types';
import Question from '../../components/question/question';

import style from './evidence-map-ncs-cobenefit.module.scss';

import EvidenceMapComponent from '../../components/evidence-map/evidence-map';
import colorbar from '../../assets/colorbar.png';
import * as Types from '../../types';

const EvidenceMapNCSCobenefit: React.FC<QuestionProps> = ({
  question,
  currentQuestion,
  totalQuestions,
  answerQuestion,
}) => {

  const [selected, setSelected] = useState<Types.GeoJSON[] | null>(
    (question.previous_answer?.answers[0].answers[0].coordinates as number[][][])?.map(e => {
      return {
        type: 'Polygon',
        coordinates: e as any,
      } as Types.GeoJSON;
    }));
  
  useEffect(() => {
    const coordinates = selected?.map((e) => e.coordinates);
    answerQuestion(question.page.questions[0].question_id,  [... coordinates ? [{
      coordinates: coordinates as any,
    }] : []]);
  }, [answerQuestion, question.page.questions, selected]);

  return (
    <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
      <div className={`${style.main}`}>
        <h2
          dangerouslySetInnerHTML={{
            __html: question.page.page_display
              ? question.page.page_display
              : '',
          }}
        ></h2>
        <div className={style.parent}>
          <div className={style.child_image}>
            <p><b>{question.page.page_helper}</b></p>
            <EvidenceMapComponent
              selected={selected}
              setSelection={setSelected}
              selectable={true}
              evidence_map={question.page.extra_data?.evidence_map} 
              selected_region={question.page.extra_data?.selected_region} />
          </div>
          <div className={style.child_text}>
            <div className={style.answers}>
              <span>{question.page.questions[0].question_display}</span>
            </div>
            <div className={style.colorbar}>
              <div className={style.left}>
                <img src={colorbar}></img>
              </div>
              <div className={style.right}>
                <div className={style.high}>
                  <span>High evidence</span>
                </div>
                <div className={style.low}>
                  <span>Low evidence</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Question>
  );
};

export default EvidenceMapNCSCobenefit;
