import { PageType } from '../../types';

// export const ProgressMap: {
//   [key in PageType]: number;
// } = {
//   'main': 0.25,
//   'overview': 0.25,
//   'progress': 0.25,
//   'incentive': 0.25,
//   'incentive_info': 0.25,
//   'consent': 0.25,
//   'primary_expertise': 0.75,
//   'ncs_pathways': 0.25,
//   'ncs_social_outcome': 0.25,
//   'social_outcome': 0.25,
//   'ncs_cobenefit': 0.25,
//   'map': 0.5,
//   'regional_expertise': 0.5,
//   'evidence_map': 0.5,
//   'ncs_map_selection': 0.5,
//   'evidence_map_ncs_cobenefit_loop': 0.5,
//   'evidence_map_ncs_social_outcome': 0.5,
//   'location_based_ncs_potential': 0.5,
//   'ncs_map_selection_loop': 0.5,
//   'ncs_constraints': 0.5,
//   'evidence_map_ncs_cobenefit': 0.5,
//   'choice_experiment': 0.5,
//   'choice_experiment_explanation': 0.5,
//   'demographic': 0.75,
//   'academic_degree': 0.75,
//   'institutional_affiliation': 0.75,
//   'choice_experiment_options': 0.5,
//   'age_range': 0.75,
//   'gender': 0.75,
//   'submit': 1,
//   'lottery': 1,
//   'thank_you': 1,
//   'thank_you_ending_page': 1,
//   '': 0,
// };

export const ProgressMap: {
  [key in PageType]: [string, string, string, string, string, string, string];
} = {
  'main': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'overview': ['#6e8a8dff', '#45818e22', '#38761d22', '#274e1322', '#77889922', '#77889922', '#77889922'],
  'progress': ['#6e8a8dff', '#45818e22', '#38761d22', '#274e1322', '#77889922', '#77889922', '#77889922'],
  'dce_to_region': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'incentive': ['#6e8a8dff', '#45818e22', '#38761d22', '#274e1322', '#77889922', '#77889922', '#77889922'],
  'incentive_info': ['#6e8a8dff', '#45818e22', '#38761d22', '#274e1322', '#77889922', '#77889922', '#77889922'],
  'consent': ['#6e8a8dff', '#45818e22', '#38761d22', '#274e1322', '#77889922', '#77889922', '#77889922'],
  'primary_expertise': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'ncs_pathways': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'ncs_social_outcome': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'social_outcome': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'ncs_cobenefit': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'map': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'regional_expertise': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'evidence_map': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'ncs_map_selection': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'evidence_map_ncs_cobenefit_loop': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'evidence_map_ncs_social_outcome': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'location_based_ncs_potential': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'ncs_map_selection_loop': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'ncs_constraints': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'evidence_map_ncs_cobenefit': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e1322', '#778899ff', '#778899ff', '#77889922'],
  'choice_experiment': ['#6e8a8dff', '#45818eff', '#38761d22', '#274e1322', '#778899ff', '#77889922', '#77889922'],
  'choice_experiment_explanation': ['#6e8a8dff', '#45818eff', '#38761d22', '#274e1322', '#778899ff', '#77889922', '#77889922'],
  'demographic': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'academic_degree': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'institutional_affiliation': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'choice_experiment_options': ['#6e8a8dff', '#45818eff', '#38761d22', '#274e1322', '#778899ff', '#77889922', '#77889922'],
  'age_range': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'gender': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'submit': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'lottery': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'thank_you': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  'thank_you_ending_page': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
  '': ['#6e8a8dff', '#45818eff', '#38761dff', '#274e13ff', '#778899ff', '#778899ff', '#778899ff'],
};
