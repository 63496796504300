import React, { useEffect, useState } from 'react';
import Button from '../../components/button/button';
import Checkbox from '../../components/checkbox/checkbox';
import Question from '../../components/question/question';
import { pdfjs } from 'react-pdf';

import logo from '../../assets/McGill_University.png';

import style from './consent-form.module.scss';
import jsPDF from 'jspdf';
import { QuestionProps } from '../../types';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const content = {
  title1: 'The Study',
  text1: [
    'Your participation is entirely voluntary and you may withdraw at any time.',
    'This study is funded through McGill research grant TNC-US #P121337-106876.',
    'You will have the option of entering a drawing to win 1 of 10 gift cards worth up to $1000 USD.',
  ],
  title2: 'Confidentiality',
  text2: [
    'The survey responses and demographic information you will share for this study will be kept confidential.',
    'All data is securely gathered and used for the purposes of this study. All data collected through this study will be password protected on a computer file and follow strict data curation guidelines.',
    'IP addresses will not be collected or stored. We will request your email address for the purpose of determining response rates, geographical representation, and potentially following up with you at a later time.',
    'Email addresses and any identifying information will not be shared or used in analysis or results of this survey. Survey responses will be assigned an automated identification number unique to this study. Only the principal investigator of the study will have access to your identifiable information, which will be securely stored and password protected on McGill-based OneDrive cloud service.',
    'Anonymized results from this study may be used to inform researchers, practitioners, and decision-makers through manuscripts, presentations, and reports.',
  ],
  title3: 'Potential Risks & Benefits',
  text3: [
    'There are no anticipated risks to you by participating in this online survey.',
    'We anticipate few benefits to you directly, but your participation will help us better understand of the potential for NCS to meet global priorities.',
  ],
  title4: 'Questions about the study',
  text4: [
    ' Any questions about this study or the survey may be directed to ncs.survey@mcgill.ca or Dr. Brian E. Robinson (brian.e.robinson@mcgill.ca), the principal investigator at McGill University. If you have any ethical concerns or complaints about your participation in this study, and want to speak with someone not on the research team, please contact the Associate Director, Research Ethics at 514-398-6831 or lynda.mcneil@mcgill.ca citing REB file number #22-11-086.',
  ],
};

const onSaveConsent = (): void => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF();
  doc.setFontSize(20);
  doc.text(content.title1, 20, 20);
  doc.setFontSize(12);
  doc.text(content.text1[0], 20, 30, { maxWidth: 170 });
  doc.text(content.text1[1], 20, 45, { maxWidth: 170 });
  doc.text(content.text1[2], 20, 55, { maxWidth: 170 });
  doc.setFontSize(20);
  doc.text(content.title2, 20, 95);
  doc.setFontSize(12);
  doc.text(content.text2[0], 20, 105, { maxWidth: 170 });
  doc.text(content.text2[1], 20, 120, { maxWidth: 170 });
  doc.text(content.text2[2], 20, 140, { maxWidth: 170 });
  doc.text(content.text2[3], 20, 160, { maxWidth: 170 });
  doc.text(content.text2[4], 20, 185, { maxWidth: 170 });
  doc.setFontSize(20);
  doc.text(content.title3, 20, 205);
  doc.setFontSize(12);
  doc.text(content.text3[0], 20, 215, { maxWidth: 170 });
  doc.text(content.text3[1], 20, 225, { maxWidth: 170 });
  doc.setFontSize(20);
  doc.text(content.title4, 20, 255);
  doc.setFontSize(12);
  doc.text(content.text4[0], 20, 265, { maxWidth: 170 });

  doc.addImage(logo, 'PNG', 170, 5, 20, 20);

  doc.save('consent.pdf');
};

const ConsentForm: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answerQuestion,
  onNextButtonClickAction,
}) => {
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(
    !!question.previous_answer?.answers[0]
  );

  useEffect(() => {
    answerQuestion(
      question.page.questions[0].question_id,
      question.page.questions[0].possible_answers
    );
  }, [answerQuestion, isConsentGiven, question.page.questions]);

  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
      maxHeight={true}
    >
      <div id="pdf" className={style.form}>
        <h1 className={style.main_title}>Survey consent form</h1>
        <h1>{content.title1}</h1>
        <ul>
          <li>{content.text1[0]}</li>
          <li>{content.text1[1]}</li>
          <li>{content.text1[2]}</li>
        </ul>
        <h1>{content.title2}</h1>
        <ul>
          <li>{content.text2[0]}</li>
          <li>{content.text2[1]}</li>
          <li>{content.text2[2]}</li>
          <li>{content.text2[3]}</li>
          <li>{content.text2[4]}</li>
        </ul>
        <h1>{content.title3}</h1>
        <ul>
          <li>{content.text3[0]}</li>
          <li>{content.text3[1]}</li>
        </ul>
        <h1>{content.title4}</h1>
        <ul>
          <li>{content.text4[0]}</li>
        </ul>
        <div className={style.download}>
          <Button onClick={onSaveConsent}>
            I want to download this consent (PDF)
          </Button>
        </div>
        <div className={style.footer}>
          <div className={style.flex}>
            <div className={style.consent}>
              <div className={style.text}>
                <h1>Participant Consent</h1>
                <p>
                  By checking this box, you confirm you have read, understand,
                  and agree to participate in this study according to the terms
                  listed above.
                </p>
              </div>
              <div className={style.checkbox}>
                <Checkbox
                  checked={isConsentGiven}
                  onChange={() => {
                    setIsConsentGiven(!isConsentGiven);
                  }}
                />
              </div>
            </div>
            <div className={style.next}>
              <Button
                disabled={!isConsentGiven}
                onClick={onNextButtonClickAction}
              >
                Start the survey
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Question>
  );
};

export default ConsentForm;
