import React from 'react';
import { NewPossibleAnswer } from '../../../types';

import style from './radio-answer.module.scss';

interface PossibleAnswerProps {
  possibleAnswer: NewPossibleAnswer;
  selected?: boolean;
  onSelect?: () => void;
  hideLabel?: boolean;
}

const RadioAnswer = ({
  selected,
  possibleAnswer,
  onSelect,
  hideLabel,
}: PossibleAnswerProps): JSX.Element => {
  return (
    <div
      className={`${style.radioAnswer} ${selected ? style.selected : ''}`}
      onClick={onSelect}
    >
      <div className={hideLabel ? style.radioHiddenLabel : style.radio}>
        <div className={`${selected ? style.checked : style.hidden}`} />
      </div>
      {!hideLabel && <p>{possibleAnswer.answer_display}</p>}
    </div>
  );
};

export default RadioAnswer;
