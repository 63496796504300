import { RefObject, useEffect } from 'react';

export const useCursorMovedOutside = (ref: RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousemove', listener);

    return () => {
      document.removeEventListener('mousemove', listener);
    };
  }, [ref, callback]);
};
