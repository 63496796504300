/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';
import { back, getCurrentPage, begin, postAnswer, submit, submitLotteryMail } from '../api/backend/api';
import { AnswerDTO } from '../api/types';
import { Alert, LotteryInterface, NewAnswerInterface, NewQuestionInterface } from '../types';
import { type SurveyState } from './survey.state';

const win: Window = window;

const useSurveyStore = create<SurveyState>((set, get) => ({
  loading: false,
  question: null,
  alert: null,
  survey_id: null,
  getQuestion: async (): Promise<NewQuestionInterface | null> => {    
    set({ loading: true });
    try {
      const cookies = document.cookie.split(';').map((c) => c.trim());
      const surveyId = new URLSearchParams(window.location.search).get('survey_id');
      const access_token = cookies.find((c) => c.startsWith('access_token='))?.split('=')[1];

      if (access_token && surveyId) {
        const question = await getCurrentPage();
        set({ loading: false, question });
        return question;
      } else if(surveyId) {
        const survey_id = new URLSearchParams(window.location.search).get('survey_id');
        if (survey_id) {
          await begin(survey_id);
          const question = await getCurrentPage();
          set({ loading: false, question });
          return question;
        }
      }
    } catch(e: any) {
      set({ loading: false, alert: { type: 'error', message: e } });
    }
    return null;
  },
  setSurveyId: (survey_id: string) => set({ survey_id }),
  begin: async (survey_id: string) => {
    set({ loading: true });
    try {
      const response = await begin(survey_id);
      // replace survey_id with the new one in the url query
      const old_survey_id = new URLSearchParams(window.location.search).get('survey_id');
      if (old_survey_id !== response.survey_id) {
        win.location = window.location.href.replace(survey_id, response.survey_id);
      }

      set({ loading: false, survey_id: response.survey_id });
    } catch (err: any) {
      window.location.href = '/participate';
    }
    
  },
  setAlert: (alert: Alert | null) => set({ alert }),
  back: async () => {
    set({ loading: true });
    const question = await back();
    set({ loading: false, question });
  },
  postAnswer: async (answer: AnswerDTO) => {
    set({ loading: true });
    const question = get().question;
    if (!question) {
      set({ loading: false, alert: { type: 'error', message: 'No question found' } });
      return;
    }
    const newAnswer: NewAnswerInterface = {
      page_name: question.page.page_name,
      page_id: question.page.page_id,
      version: question.page.version,
      answers: question.page.questions.map((q) => {
        return {
          question_id: q.question_id,
          question_name: q.question_name,
          version: q.version,
          group: q.group,
          group_display: q.group_display,
          answers: answer.find(e=>e.questionId === q.question_id)?.answers ?? [],
        };
      })
    };
    const nextQuestion = await postAnswer(newAnswer);
    set({ loading: false, question: nextQuestion });
  },
  submit: async() => {
    set({ loading: true });
    const question = get().question;
    if (!question) {
      set({ loading: false, alert: { type: 'error', message: 'No question found' } });
      return;
    }

    const nextQuestion = await submit();
    set({ loading: false, question: nextQuestion });
  },
  submitLottery: async(data: LotteryInterface) => {
    set({ loading: true });
    const question = get().question;
    if (!question) {
      set({ loading: false, alert: { type: 'error', message: 'No question found' } });
      return;
    }

    const nextQuestion = await submitLotteryMail(data);
    set({ loading: false, question: nextQuestion });
  }
}));

export default useSurveyStore;
