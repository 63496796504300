/* eslint-disable @typescript-eslint/no-explicit-any */
import FreeTextAnswer from '../components/possible-answer/free-text-answer/free-text-answer';
import RadioAnswer from '../components/possible-answer/radio-answer/radio-answer';
import MultiSelectQuestion from './question-multi-select/question-multi-select';
import RadioQuestion from './question-radio/question-radio';
import countries from '../assets/data.json';
import { Biom, CoBenefit, PageType } from '../types';
import ConsentForm from './consent-form/consent-form';
import MapQuestion from './question-map/question-map';
import MultiselectWithBiomQuestion from './question-multiselect-with-biom/question-multiselect-with-biom';
import MultiSelectRadioGroupQuestion from './question-multiselect-radio-group/question-multiselect-radio-group';

import IncentiveQuestion from './question-incentive/question-incentive';
import CheckboxFreeTextAnswer from '../components/possible-answer/checkbox-free-text/checkbox-free-text-answer';
import OverviewPage from './overview-page/overview-page';
import ProgressPage from './progress-page/progress-page';
import DCEToRegionPage from './dce-to-region/dce-to-region';
import Lottery from './lottery/lottery';
import EvidenceMap from './question-evicence-map/question-evidence-map';
import PrimaryExpertisePage from './primary-expertise/primary-expertise';
import CoverPage from '../components/cover-page/cover-page';
import RegionalExpertiseQuestion from './question-regionalexpertise/question-regional-experties';
import NCSConstraintsQuestion from './question-ncs-constraints/question-ncs-constraints';
import SubmitPage from './submit-page/submit-page';
import _ from 'lodash';
import ThankYouPage from './thank-you-page/thank-you-page';

import forest from '../assets/bioms/forest.webp';
import grassland from '../assets/bioms/grassland.webp';
import wetland from '../assets/bioms/wetland.webp';

import money from '../assets/cobenefits/econ_material_wellbeing.webp';
import meditation from '../assets/cobenefits/culture.webp';
import padlock from '../assets/cobenefits/security.webp';
import openbook from '../assets/cobenefits/education.webp';
import hospital from '../assets/cobenefits/health.webp';
import biodiversity from '../assets/cobenefits/biodiversity.webp';
import savetheworld from '../assets/cobenefits/environment.webp';
import moreinformation from '../assets/cobenefits/other.webp';

import * as Types from '../types';
import EvidenceMapNCSCobenefit from './question-evidence-map-ncs-cobenefit/evidence-map-ncs-cobenefit';
import MapLoopQuestion from './question-map-loop/question-map-loop';
import ChoiceExperimentStatic1 from './choice-experiment-1/choice-experiement-1';
import ChoiceExperimentStatic2 from './choice-experiment-2/choice-experiment-2';
import ChoiceExperimentQuestion from './question-choicee-experiment/question-choice-experiment';
import IncentiveInfoPage from './question-incentive-info/question-incentive-info';
import ThankYouEndingPage from './thank-you-ending-page/thank-you-ending-page';
import ChoiceExperimentQuestionNew from './question-choice-experiment-new/question-choice-experiment-new';

export const QuestionTypeMap: {
  [key in PageType]: React.FC<any> | undefined;
} = {
  main: CoverPage,
  consent: ConsentForm,
  map: MapQuestion,
  evidence_map: EvidenceMap,
  lottery: Lottery,
  incentive: IncentiveQuestion,
  incentive_info: IncentiveInfoPage,
  primary_expertise: RadioQuestion,
  overview: OverviewPage,
  progress: ProgressPage,
  dce_to_region: DCEToRegionPage,
  ncs_pathways: MultiselectWithBiomQuestion,
  social_outcome: MultiSelectQuestion,
  ncs_social_outcome: MultiSelectRadioGroupQuestion,
  ncs_cobenefit: MultiSelectRadioGroupQuestion,
  regional_expertise: RadioQuestion,
  ncs_map_selection: MapQuestion,
  demographic: RadioQuestion,
  choice_experiment: ChoiceExperimentStatic1,
  choice_experiment_explanation: ChoiceExperimentStatic2,
  location_based_ncs_potential: RegionalExpertiseQuestion,
  ncs_constraints: NCSConstraintsQuestion,
  evidence_map_ncs_social_outcome: EvidenceMap,
  evidence_map_ncs_cobenefit_loop: RadioQuestion,
  evidence_map_ncs_cobenefit: EvidenceMapNCSCobenefit,
  ncs_map_selection_loop: RadioQuestion,
  choice_experiment_options: ChoiceExperimentQuestionNew,
  submit: SubmitPage,
  academic_degree: RadioQuestion,
  institutional_affiliation: RadioQuestion,
  age_range: RadioQuestion,
  gender: RadioQuestion,
  thank_you: ThankYouPage,
  thank_you_ending_page: ThankYouEndingPage,
  '': undefined,
};

export const BiomIcons: {
  [key in Biom]: string;
} = {
  'Forests': forest,
  'Grasslands & Agricultural Lands': grassland,
  'Wetlands': wetland,
  '': '',
};

export const CoBenefitIcons: {
  [key in CoBenefit]: string;
} = {
  'Economic living standards': money,
  'Economic or material living standards': money,
  'Culture & spirituality': meditation,
  'Security & safety': padlock,
  'Education': openbook,
  'Health': hospital,
  'Biodiversity': biodiversity,
  'Environmental co-benefits': savetheworld,
  'Other': moreinformation,
};

export const AnswerTypeMap = {
  radio: RadioAnswer,
  radio_freetext: FreeTextAnswer,
  checkbox_freetext: CheckboxFreeTextAnswer,
  '': undefined,
};

export const getZoom = (geo: number[][] | number[][][] | number[][][][]): number => {

  const lats: number[] = _.flatMapDeep(geo as number[][][]).filter((c, i) => i % 2 === 0);

  const lngs: number[] = _.flatMapDeep(geo as number[][][]).filter((c, i) => i % 2 === 1);

  if (lats.length > 0 && lngs.length > 0) {
    const latMin = Math.min(...lats);
    const latMax = Math.max(...lats);

    const lngMin = Math.min(...lngs);
    const lngMax = Math.max(...lngs);

    const latDiff = latMax - latMin;
    const lngDiff = lngMax - lngMin;

    const latZoom = Math.log(360 / latDiff) / Math.log(2);
    const lngZoom = Math.log(360 / lngDiff) / Math.log(2);

    const zoom = Math.min(latZoom, lngZoom);

    return Math.round(zoom - 1);
  }
  return 0;
};

export const convertToGeoJson = (geo: number[][] | number[][][] | undefined): any => {
  if (!geo) return null;
  if (geo.length === 1) return {
    type: 'Polygon',
    coordinates: geo,
  };

  return {
    type: 'MultiPolygon',
    coordinates: geo,
  };
};

export const getCenter = (geo: number[][][] | number[][][][]): number[] => {
  if (geo.length === 1) {
    
    const lats = (geo as number[][][]).reduce((acc: number[], curr: number[][]) => {
      return [...acc, ...curr.map((c) => c[0])];
    }, []);
    const lngs = (geo as number[][][]).reduce((acc: number[], curr: number[][]) => {
      return [...acc, ...curr.map((c) => c[1])];
    }, []);
  
    if (lats.length > 0 && lngs.length > 0) {
      const lat = lats.reduce((acc, curr) => acc + curr, 0) / lats.length;
      const lng = lngs.reduce((acc, curr) => acc + curr, 0) / lngs.length;
      return [lat, lng];
    }
  }else {
    const lats: number[] = _.flatMapDeep(geo as number[][][]).filter((c, i) => i % 2 === 0);
    
    const lngs: number[] = _.flatMapDeep(geo as number[][][]).filter((c, i) => i % 2 === 1);
    
    if (lats.length > 0 && lngs.length > 0) {
      const lat = lats.reduce((acc, curr) => acc + curr, 0) / lats.length;
      const lng = lngs.reduce((acc, curr) => acc + curr, 0) / lngs.length;
      return [lat, lng];
    }
  }

  return [0, 0];
};

export const getHoveredBoundary = (geo: { 
  name: string; country: string; geometry: Types.GeoJSON }[], lat: number, lng: number): Promise< { 
    name: string; country: string; geometry: Types.GeoJSON } | undefined> => {
  return new Promise((resolve) => {
    if (!geo) resolve(undefined);
    const isInside = (point: number[], vs: number[][]): boolean => {
      if (!vs) return false;
      const x = point[0],
        y = point[1];

      let inside = false;
      for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0],
          yi = vs[i][1];
        const xj = vs[j][0],
          yj = vs[j][1];
        
        const intersect =
          (yi > y) !== (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }

      return inside;
    };

    const boundary = geo.find((e) => {
      if (e.geometry.type === 'Polygon') {
        return isInside([lng, lat], e.geometry.coordinates[0] as any);
      } else if (e.geometry.type === 'MultiPolygon') {
        return e.geometry.coordinates.find((c) => isInside([lng, lat], c[0] as any));
      }
      return false;
    });

    resolve(boundary);
  });
};

export const getCountry = (lat: number, lng: number): Promise<any> => {
  return new Promise((resolve) => {
    const isInside = (point: number[], vs: number[][]): boolean => {
      if (!vs) return false;
      const x = point[0],
        y = point[1];

      let inside = false;
      for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0],
          yi = vs[i][1];
        const xj = vs[j][0],
          yj = vs[j][1];

        const intersect =
          (yi > y) !== (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }

      return inside;
    };
    const country = countries.find((e) => {
      if (e.data[0]?.geojson.type === 'Polygon')
        return e.data[0]?.geojson.coordinates.some((e: any) =>
          isInside([lng, lat], e)
        );
      if (e.data[0]?.geojson.type === 'MultiPolygon')
        return e.data[0]?.geojson.coordinates.some((e: any) => {
          return e.some((e: any) => isInside([lng, lat], e));
        });
      return false;
    });
    resolve(country);
  });
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
