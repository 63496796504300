import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnswerData, QuestionData, QuestionProps } from '../../types';
import Question from '../../components/question/question';
import infographics from '../../assets/main/infographics.webp';
import target_icon from '../../assets/choice_experiment_icons/target.webp';
import location_icon from '../../assets/choice_experiment_icons/location.webp';
import dollar_icon from '../../assets/choice_experiment_icons/dollar.webp';

import reforesting_agricultural_land from '../../assets/choice_experiment_pictures/0_reforesting_agricultural_land.webp';
import reforesting_rangeland from '../../assets/choice_experiment_pictures/1_reforesting_rangeland.webp';
import avoided_grassland_conversion from '../../assets/choice_experiment_pictures/2_avoided_grassland_conversion.webp';
import peatland_restoration from '../../assets/choice_experiment_pictures/3_peatland_restoration.webp';
import agroforestry from '../../assets/choice_experiment_pictures/4_Agriforestry.webp';
import costal_wetland_restoration from '../../assets/choice_experiment_pictures/5_costal_wetland_restoration.webp';
import nutrient_management from '../../assets/choice_experiment_pictures/6_Nutrient_management_in_agricultural_land.webp';
import improved_forest_management from '../../assets/choice_experiment_pictures/7_improved_forest_management.webp';
import avoided_forest_conversion from '../../assets/choice_experiment_pictures/8_avoided_forest_conversion.webp';
import planting_trees from '../../assets/choice_experiment_pictures/9_planting_trees.webp';
import percentage_10 from '../../assets/choice_experiment_bars/percentage_10.jpg';
import percentage_30 from '../../assets/choice_experiment_bars/percentage_30.jpg';
import percentage_40 from '../../assets/choice_experiment_bars/percentage_40.jpg';
import percentage_60 from '../../assets/choice_experiment_bars/percentage_60.jpg';

import style from './question-choice-experiment-new.module.scss';
import RadioAnswer from '../../components/possible-answer/radio-answer/radio-answer';
import Tooltip from '../../components/tooltip/tooltip';

import info from '../../assets/tooltip/info.png';

const ChoiceExperimentQuestionNew: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answerQuestion,
}) => {
  const [selected, setSelected] = useState<string | undefined>(
    question.previous_answer?.answers[0].answers[0].answer_id
  );

  useEffect(() => {
    const answer = question.page.questions[0].possible_answers?.find(
      (e) => e.answer_id === selected
    );
    answerQuestion(
      question.page.questions[0].question_id,
      answer ? [answer] : []
    );
  }, [answerQuestion, question.page.questions, selected]);

  interface PicsMap {
    [key: string]: string;
  }

  interface BarMap {
    [key: string]: string;
  }

  interface InfoMap {
    [key: string]: string;
  }

  const pics_map: PicsMap = {
    'Reforesting agricultural land': reforesting_agricultural_land,
    'Reforesting rangeland': reforesting_rangeland,
    'Avoided grassland conversion': avoided_grassland_conversion,
    'Peatland restoration': peatland_restoration,
    'Agroforestry': agroforestry,
    'Coastal wetland restoration': costal_wetland_restoration,
    'Nutrient management in agricultural land': nutrient_management,
    'Improved forest management': improved_forest_management,
    'Avoided forest conversion': avoided_forest_conversion,
    'Planting trees': planting_trees,
  };

  const bar_map: BarMap = {
    '10%': percentage_10,
    '30%': percentage_30,
    '40%': percentage_40,
    '60%': percentage_60,
  };

  const info_map:InfoMap = {
    'Reforesting agricultural land': 'Increase carbon sequestration by reforesting agricultural areas that were historically forest, or are abandoned farm land.',
    'Reforesting rangeland': 'Increase carbon sequestration by reforesting grassland areas that were historically forest, or are abandoned pastures.',
    'Avoided grassland conversion': 'Avoid soil carbon emissions by preventing the conversion of grasslands (including savannas and shrublands) to cropland.',
    'Peatland restoration': 'Increase carbon sequestration by restoring peatlands (nutrient-rich freshwater wetlands like moors, bogs, mires, swamp forests, and permafrost tundra - peatlands make up ½ the world’s wetlands and are present all over the world).',
    'Agroforestry': 'Increase carbon sequestration by integrating trees into croplands.',
    'Coastal wetland restoration': 'Increase carbon sequestration in above- and below-ground biomass by restoring coastal wetlands (mangroves, tidal marshes, and seagrass meadows).',
    'Nutrient management in agricultural land': 'Reduce N2O emissions by managing the over-application of fertilizers.',
    'Improved forest management': 'Avoid emissions and/or increase sequestration in working forest through activities such as reduced-impact logging practices, deferred harvest, and enhanced forest regeneration in post-harvest stands.',
    'Avoided forest conversion': 'Avoid carbon emissions by preventing human conversion of forest to non-forest land uses such as agricultural, urban, or industrial lands.',
    'Planting trees': 'Increase carbon sequestration by planting trees.',
  };

  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.question}>

        <div className={style.title}>
          <h2
            dangerouslySetInnerHTML={{
              __html: question.page.page_display
                ? question.page.page_display
                : '',
            }}
          ></h2>
          <p>{question.page.questions[0].question_display}</p>
        </div>{' '}
        <div className={style.answers}>
          {question.page.questions[0].possible_answers.map(
            (possibleAnswer, index) => {
              let project = '';
              let fund = '0';
              let density = '';
              let income = '';
              let location = '';
              if (
                question.page.questions[0].extra_data &&
                possibleAnswer.answer_display !== 'Neutral'
              ) {
                Object.entries(
                  question.page.questions[0].extra_data[
                    possibleAnswer.answer_display
                  ]
                ).forEach(([jsonKey, jsonData]) => {
                  if (jsonKey.startsWith('Inter')) {
                    project = jsonData;
                  } else if (jsonKey.startsWith('Fund')) {
                    fund = jsonData;
                  } else {
                    location = jsonData[0];
                    income = jsonData[1];
                    density = jsonData[2];
                  }
                });
              }

              if (project == '') {
                return (
                  <div key={index} className={style.answer_narrow}>
                    <div className={style.title_neither}>
                      <h3>{possibleAnswer.answer_name}</h3>
                    </div>
                    <div className={style.content}>
                      <>
                        <RadioAnswer
                          selected={possibleAnswer.answer_id === selected}
                          onSelect={() => setSelected(possibleAnswer.answer_id)}
                          possibleAnswer={possibleAnswer}
                          hideLabel={true}
                        />
                      </>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index} className={style.answer}>
                    <div className={style.title}>
                      <h3>{possibleAnswer.answer_name}</h3>
                      <div className={style.project_aim}>
                        <img src={target_icon} alt={'icon'} />
                        <h4>Project aim:</h4>
                      </div>
                      <div className={style.p_container}>
                        <h4 className={style.project_aim_text}>{project}</h4>
                        <Tooltip
                          text={info_map[project]}
                        >
                          <img className={style.info_icon} src={info} alt="info" />
                        </Tooltip>
                      </div>
                      <br></br>
                      <div className={style.picture}>
                        <img src={pics_map[project]} alt={'icon'} />
                      </div>
                    </div>
                    <br></br>
                    <div className={style.location}>
                      <img src={location_icon} alt={'icon'} />
                      <h4>Location</h4>
                    </div>
                    <br></br>
                    <div className={style.content}>
                      <div>
                        <div className={style.text}>
                          <b>{density}</b> in <b>{location}</b> with
                          relatively <b>{income} levels of income</b> (within this
                            continent/region).
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className={style.location}>
                      <img src={dollar_icon} alt={'icon'} />
                      <h4>Percentage of funding to allocate</h4>
                    </div>
                    <img
                      className={style.fund_bar_img}
                      src={bar_map[fund]}
                      alt={'icon'}
                    />
                    <h3 className={style.fund_bar_h3}>{fund}</h3>
                    <>
                      <RadioAnswer
                        selected={possibleAnswer.answer_id === selected}
                        onSelect={() => setSelected(possibleAnswer.answer_id)}
                        possibleAnswer={possibleAnswer}
                        hideLabel={true}
                      />
                    </>
                  </div>
                );
              }
            }
          )}

        </div>
        <div className={style.counter}>
          {question.page.extra_data?.choice_experiment_card_number} / 3 choice<br></br>questions
        </div>
      </div>
    </Question>
  );
};

export default ChoiceExperimentQuestionNew;
