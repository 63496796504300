import React from 'react';
import style from './button.module.scss';

export interface ButtonProps {
  children?: React.ReactNode;
  primary?: boolean;
  disabled?: boolean;
  onClick?: () => void
  stylecolor?: React.CSSProperties | undefined
}

const Button = ({ children, primary, disabled, onClick, stylecolor}: ButtonProps): JSX.Element => {
  return (
    <div className={style.button}>
      <button
        style={stylecolor}
        disabled={disabled}
        className={`${primary ? style.primary : style.secondary} ${disabled ? style.disabled : ''}`}
        onClick={() => {
          if (!disabled && onClick) onClick();
        }}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
