import React, { ChangeEvent } from 'react';
import { NewPossibleAnswer } from '../../../types';

import style from './free-text-answer.module.scss';

interface FreeTextAnswerProps {
    possibleAnswer: NewPossibleAnswer;
    selected?: boolean;
    value: string
    onClick?: () => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const FreeTextAnswer = ({ value, selected, onClick, onChange }: FreeTextAnswerProps) => {
  return <div className={style.freeTextAnswer} onClick={onClick}>
    <div className={style.radio}>
      <div className={`${selected ? style.checked : style.hidden}`} />
    </div>
    <p>
            Other:
    </p>
    <input placeholder="Please specify" value={value} onChange={onChange}/>
  </div>;
};

export default FreeTextAnswer;
