import React from 'react';
import { Alert, AlertType } from '../../types';
import style from './alert.module.scss';

import check from '../../assets/alert/check.png';
import close from '../../assets/alert/close.png';
import info from '../../assets/alert/info.png';
import warning from '../../assets/alert/warning.png';
import Button from '../button/button';

const icon: {
  [key in AlertType]: string;
} = {
  success: check,
  error: close,
  info: info,
  warning: warning,
};

const AlertComponent = ({ type, message, onDismiss }: Alert & { onDismiss: () => void }): JSX.Element => {
  return (
    <div className={style.container}>
      <div className={style.alert}>
        <div className={style.header}>
          <div className={style.icon}>
            <img src={icon[type]} alt={type} />
          </div>
        </div>
        <div className={style.body}>
          <h1>{type}</h1>
          <p>{message}</p>
        </div>
        <div className={style.footer}>
          <Button onClick={onDismiss}>Dismiss</Button>
        </div>
      </div>
    </div>
  );
};

export default AlertComponent;
