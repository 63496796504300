import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../button/button';

import style from './back-button.module.scss';

interface BackButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const BackButton = ({ onClick, disabled }: BackButtonProps): JSX.Element => {
  return (
    <div className={style.backButton}>
      <Button disabled={disabled} onClick={onClick}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <p>Back</p>
      </Button>
    </div>
  );
};

export default BackButton;
