import React from 'react';
import Question from '../../components/question/question';

import style from './choice-experiment-2.module.scss';
import { QuestionProps } from '../../types';

const ChoiceExperimentStatic2: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
}) => {
  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <h1>{question.page.page_title}</h1>
        <div className={style.parent}>
          <p>
            Each option will contain a location, a level of wealth relative to
            the location, and a setting based primarily on population density
            (urban, rural, etc.). 
            <br></br>
            <br></br>
            In each option there is an intervention
            presented. The intervention is a possible climate-mitigating action
            that would be implemented on a current type of biome or land use.
            For example, Option A to the right proposes an intervention to
            improve management on current rangelands in a particular location
            and context.
          </p>
          <div className={style.bottom}>
            <div className={style.child_text}>
              Assuming this option will help mitigate greenhouse gas emissions,
              you will first be asked:{' '}
              <b>
                in this context, how would this intervention relate to other
                social outcomes?
              </b>{' '}
              A social outcome will be randomly chosen for you to evaluate.
              You’ll have the option of saying it relates to an option in a
              positive (＋), negative (－), or neutral/not applicable (○) way.
            </div>
            <div className={style.child_table}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Option A</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Intervention (current land use → NCS action)</td>
                    <td>Rangeland → Improved management</td>
                  </tr>
                  <tr>
                    <td>Location and context</td>
                    <td>
                      <ul>
                        <li>North America</li>
                        <li>Low income</li>
                        <li>Rural (low population density)</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={style.bottom}>
            <div className={style.child_text}>
              You will then be given a proposed percent of the Program
              Director’s budget that would be used for this intervention. You
              will be asked: <b>given what you know, is it worth putting this
              percent of the budget toward this intervention?</b>
            </div>
            <div className={style.child_table_lower}>
              <table>
                <tbody>
                  <tr>
                    <td>% budget allocation</td>
                    <td>25%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Question>
  );
};

export default ChoiceExperimentStatic2;
