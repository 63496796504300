import React from 'react';
import Question from '../../components/question/question';
import style from './choice-experiment-1.module.scss';
import { QuestionProps } from '../../types';
import target_icon from '../../assets/choice_experiment_icons/target.webp';
import location_icon from '../../assets/choice_experiment_icons/location.webp';
import dollar_icon from '../../assets/choice_experiment_icons/dollar.webp';

const ChoiceExperimentStatic1: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
}) => {
  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <h1>{question.page.page_title}</h1>
        <div className={style.parent}>
          <p>
            A key question for decision-makers is: <b>which land-based climate
            mitigation strategies are best, and under what conditions?</b> We would
            like your opinion on which options are best in specific contexts.
          </p>

          <p>
            <b>Your role: </b>
            In this game, you are advising the Director of a greenhouse gas mitigation fund.
            The fund has {question.page.extra_data?.fund_amount}.
            Your job is to make recommendations for which restoration projects to support.
          </p>

          <p>
            <b>Project attributes.</b> Each project option will have a:<br></br>
            <br></br>
            <table>
              <tbody>
                <tr>
                  <td>
                    <img src={target_icon} alt={'icon'} />
                  </td>
                  <td>Project Aim</td>
                  <td className={style.description}>
                    the land-based strategy that aims to mitigate greenhouse gas
                    emissions
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={location_icon} alt={'icon'} />
                  </td>
                  <td>Location</td>
                  <td className={style.description}>
                    the continent, level of development, and the local
                    community’s relative socioeconomic status
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={dollar_icon} alt={'icon'} />
                  </td>
                  <td>Budget Allocation</td>
                  <td className={style.description}>
                    the % of the budget that would go toward fulfilling the
                    project aim in that location
                  </td>
                </tr>
              </tbody>
            </table>
          </p>

          <p>
            <b>The task: </b>
            On each of the next three pages,
            you will receive detailed information about two projects and their respective contexts.
            Compare the two projects on each page and choose the one you think is best.
          </p>

          <p>
            For each choice, please carefully weigh the project aim, the
            location/context, and the budget that would be allocated to the
            project. There are no correct answers.
            We simply want to understand <i>your preferences</i> for these actions.
          </p>
        </div>
      </div>
    </Question>
  );
};

export default ChoiceExperimentStatic1;
