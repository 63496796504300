import React from 'react';
import Question from '../../components/question/question';

import infographics from '../../assets/main/infographics.webp';

import style from './overview-page.module.scss';
import { QuestionProps } from '../../types';

const MainPage: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
}) => {
  return (
    <Question
      showQuestionNumber={false}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
    >
      <div className={style.main}>
        <p>{question.page.page_title}</p>
        <h1>{question.page.page_display}</h1>
        <div className={style.parent}>
          <div className={style.child_text}>
            Over the past few decades, the scientific literature has described
            how various land management activities like agroforestry, wetland
            restoration, forest protection can mitigate the release of
            greenhouse gases. Around 20 of these activities have
            been described collectively as{' '}
            <b>Natural Climate Solutions (NCS)</b> that can help store or
            mitigate the release of greenhouse gases (see, e.g.{' '}
            <a
              href="https://www.pnas.org/doi/10.1073/pnas.1710465114"
              target="_blank"
              rel="noreferrer"
            >
              Griscom et al 2017
            </a>
            ). NCS are land-based conservation, restoration, and management
            actions which have the potential to mitigate up to 30% of the
            emissions to keep global warming limited to 1.5 degrees C. 
            
            <br></br><br></br><br></br>
            Yet the
            amount of land required to reach this potential is vast, and will
            affect other aspects of society. Thus, in addition to greenhouse gas 
            mitigation, NCS has the potential to impact people and/or nature in many ways.

          </div>
          <div className={style.child_image}>
            <img src={infographics} />
          </div>
        </div>
        <div className={style.bottom}>
          <b>
            We would like to hear about your opinions and experience on
            NCS-related actions, and other related social costs or benefits that
            come along with these.
          </b><br></br><br></br>
          This survey will take approximately 10 minutes to complete.
        </div>

        <footer>
          To learn more about our broader research project and meet the team,
          see our{' '}
          <a
            href="https://www.mcgill.ca/naturalclimatesolutions/"
            target="_blank"
            rel="noreferrer"
          >
            project website at McGill
          </a>
          .<br></br>
          To learn more about NCS in general,{' '}
          <a
            href="https://www.nature.org/en-us/what-we-do/our-priorities/tackle-climate-change/climate-change-stories/natural-climate-solutions-science/"
            target="_blank"
            rel="noreferrer"
          >
            TNC’s NCS Resource Center
          </a>{' '}
          is a great place to start.
        </footer>
      </div>
    </Question>
  );
};

export default MainPage;
