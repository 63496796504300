import Button from '../button/button';
import style from './cover-page.module.scss';

import welcome1 from '../../assets/cover/cover.webp';
import React, { useEffect, useRef, useState } from 'react';
import { NewQuestionInterface } from '../../types';

interface CoverPageProps {
  onClick: () => void;
  question: NewQuestionInterface;
}

const CoverPage = ({ onClick }: CoverPageProps) => {

  const [hovered, setHovered] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    const button = buttonRef.current;
    button?.addEventListener('mouseenter', onMouseEnter);
    button?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      button?.removeEventListener('mouseenter', onMouseEnter);
      button?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [buttonRef]);

  return (
    <div className={style.cover}>
      <div className={style.title}>
        <h1>Natural Climate Solutions</h1>
        <h2>+ human well-being</h2>
        <h3>A global survey of experts</h3>
        <div ref={buttonRef} className={style.button}>
          <Button onClick={onClick}>
            <p>Get Started</p>
          </Button>
        </div>
        <div className={style.footNote}>
          <p>
          A joint research project between McGill University, The Nature Conservancy, and Pomona College
          </p>
        </div>
      </div>
      <img className={`${style.pic1} ${hovered ? style.hovered : ''}`} src={welcome1} alt="welcome1" />
    </div>
  );
};

export default CoverPage;
