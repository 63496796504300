import React, { useEffect } from 'react';
import './App.scss';
import Page from './page';
import useApi from './hooks/api';
import useSurveyStore from './store/survey.store';

function App() {
  
  const { next, answerQuestion, canProceed } = useApi();
  const { loading, question, alert, setAlert, back, getQuestion } = useSurveyStore();

  useEffect(() => {
    getQuestion();
  }, [getQuestion]);

  return (
    <Page
      next={next} 
      answerQuestion={answerQuestion} 
      canProceed={canProceed}
      question={question}
      alert={alert}
      setAlert={setAlert}
      back={back}
      getQuestion={getQuestion}
      loading={loading}
    />
  );
}

export default App;
