import React, { ChangeEvent, useEffect, useState } from 'react';
import RadioAnswer from '../../components/possible-answer/radio-answer/radio-answer';
import Question from '../../components/question/question';
import { NewPossibleAnswer, QuestionProps } from '../../types';

import style from './question-incentive.module.scss';
import useSurveyStore from '../../store/survey.store';

const IncentiveQuestion: React.FC<QuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answerQuestion,
}) => {
  const { setAlert } = useSurveyStore();
  const [selectedAnswer, setSelectedAnswer] = useState<NewPossibleAnswer>(
    question.previous_answer?.answers[0].answers[0] ||
      question.page.questions[0].possible_answers[0]
  );

  const [selectedDonation, setSelectedDonation] = useState<NewPossibleAnswer>(
    question.previous_answer?.answers[1].answers[0] ||
      question.page.questions[1].possible_answers[0]
  );

  useEffect(() => {
    const data: NewPossibleAnswer = { ...selectedAnswer };

    answerQuestion(question.page.questions[0].question_id, [data]);
  }, [answerQuestion, question.page.questions, selectedAnswer]);

  useEffect(() => {
    const data: NewPossibleAnswer = { ...selectedDonation };

    answerQuestion(question.page.questions[1].question_id, [data]);
  }, [answerQuestion, question.page.questions, selectedDonation]);

  const handleSelectDonation = (donation: NewPossibleAnswer) => {
    setSelectedDonation(donation);
  };

  return (
    <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
      <div>
        <h1>{question.page.page_title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: question.page.page_display
              ? question.page.page_display
              : '',
          }}
        ></p>
        <p
          dangerouslySetInnerHTML={{
            __html: question.page.page_helper ? question.page.page_helper : '',
          }}
        ></p>
        <div className={style.parent_answers}>
          <div className={style.answers}>
            <p>{question.page.questions[0].question_display}</p>
            {question.page.questions[0].possible_answers.map(
              (possibleAnswer) => {
                return (
                  <div key={possibleAnswer.answer_id}>
                    <>
                      <RadioAnswer
                        selected={
                          selectedAnswer?.answer_id === possibleAnswer.answer_id
                        }
                        onSelect={() => {
                          setSelectedAnswer(possibleAnswer);
                        }}
                        possibleAnswer={possibleAnswer}
                      />
                    </>
                  </div>
                );
              }
            )}
          </div>

          <div className={style.answers2}>
            {question.page.questions[1].visibility_condition?.includes(
              selectedAnswer.answer_name
            ) && (
              <div>
                <p>{question.page.questions[1].question_display}</p>
                <div className={style.bioms}>
                  {question.page.questions[1].possible_answers.map(
                    (possibleAnswer) => {
                      return (
                        <div key={possibleAnswer.answer_id}>
                          <>
                            <RadioAnswer
                              selected={
                                selectedDonation?.answer_id ===
                                possibleAnswer.answer_id
                              }
                              onSelect={() => {
                                handleSelectDonation(possibleAnswer);
                              }}
                              possibleAnswer={possibleAnswer}
                            />
                          </>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Question>
  );
};

export default IncentiveQuestion;
