import React from 'react';
import Loading from './components/loading/loading';
import Navigation from './components/navigation/navigation';

import background from './assets/cover/cover.webp';
import AlertComponent from './components/alert/alert';
import { QuestionTypeMap } from './pages/util';
import useQueryParams from './hooks/query';
import { Alert, NewPossibleAnswer, NewQuestionInterface } from './types';
import NotInvitedPage from './pages/notinvited/notinvited';
import ParticipatePage from './pages/participate/participate';

interface PageProps {
  next: () => void;
  answerQuestion: (questionId: string, answers: NewPossibleAnswer[]) => void
  canProceed: boolean;
  loading: boolean;
  question: NewQuestionInterface | null;
  alert: Alert | null;
  setAlert: (alert: Alert | null) => void;
  back: () => void;
  getQuestion: () => void;
}

const Page: React.FC<PageProps> = ({
  next,
  answerQuestion,
  canProceed,
  question,
  alert,
  setAlert,
  back,
  loading,
}: PageProps) => {
  const surveyId = useQueryParams(question);

  if (window.location.pathname === '/participate') {
    return (
      <ParticipatePage alert={alert} onClick={()=>null} />
    );
  }

  if(!surveyId) {
    window.location.href = 'https://www.mcgill.ca/naturalclimatesolutions/';
    return null;
  }

  const ComponentToRender = QuestionTypeMap[question?.page.page_name ?? ''];

  const onBackButtonClickAction = (): void => {
    back();
  };

  const onDismissAlert = (): void => {
    setAlert(null);
  };
  if (!surveyId) return <NotInvitedPage />;

  return (
    <div className="App">
      {alert && <AlertComponent {...alert} onDismiss={onDismissAlert} />}
      <img className="background" src={background} alt="background" />
      <div className="container">
        <div className={`flex ${question?.page.page_name === 'main' ? 'noPadding' : ''}`}>
          {ComponentToRender === undefined && (
            <Loading currentQuestion={0} totalQuestions={18} />
          )}
          {(ComponentToRender != null) &&
            (question !== null) &&
            (!loading
              ? (
                <ComponentToRender
                  question={question}
                  currentQuestion={0}
                  totalQuestions={18}
                  onNextButtonClickAction={next}
                  onClick={next}
                  answerQuestion={answerQuestion}
                />
              )
              : (
                <Loading
                  currentQuestion={0}
                  totalQuestions={18}
                />
              ))}
        </div>
        {
          question && question?.page.page_name !== 'main' && 
            question?.page.page_name !== 'consent' && 
            question?.page.page_name !== 'submit' && 
            question?.page.page_name !== 'lottery' &&
            question?.page.page_name !== 'thank_you_ending_page' && (
            <Navigation
              canProceed={canProceed}
              loading={loading}
              question={question}
              currentQuestion={0}
              questionIds={[]}
              onNextButtonClickAction={next}
              onBackButtonClickAction={onBackButtonClickAction}
            />
          )
        }
      </div>
    </div>
  );
};

export default Page;
