import welcome1 from '../../assets/cover/cover.webp';
import React, { useEffect, useRef, useState } from 'react';

import style from './participate.module.scss';
import Button from '../../components/button/button';
import Input from '../../components/input/input';
import { validateEmail } from '../util';
import useSurveyStore from '../../store/survey.store';
import { Alert, InvitationInterface } from '../../types';
import AlertComponent from '../../components/alert/alert';
import { participate } from '../../api/backend/api';

interface CoverPageProps {
  onClick: () => void;
  alert: Alert | null;
}

const ParticipatePage = ({ alert }: CoverPageProps) => {

  const [hovered, setHovered] = useState<boolean>(false);
  const [uname, setUname] = useState<string>('');
  const [org, setOrg] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const { setAlert } = useSurveyStore();
  const [tried, setTried] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const buttonRef = useRef<HTMLDivElement>(null);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    const button = buttonRef.current;
    button?.addEventListener('mouseenter', onMouseEnter);
    button?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      button?.removeEventListener('mouseenter', onMouseEnter);
      button?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [buttonRef]);

  const onParticipate = async () => {
    setTried(true);
    if (validateEmail(email)) {
      const body_data: InvitationInterface = {
        mail: email,
        user_name: uname,
        organization: org,
      };
      setLoading(true);
      participate(body_data).then(response => {
        setLoading(false);
        setAlert({
          message: response.msg,
          type: response.status == 'success' ? 'info' : 'warning',
        });
      }).catch(() => {
        setLoading(false);
        setAlert({
          message: 'You are already participated!',
          type: 'info',
        });
      });
       
    } else {
      setAlert({
        message: 'Not a valid email address!',
        type: 'warning',
      });
    }
  };
  
  const onDismissAlert = (): void => {
    setAlert(null);
  };

  return (
    <div className={style.cover}>
      {alert && <AlertComponent {...alert} onDismiss={onDismissAlert} />}

      <div className={style.title}>
        <h1>Natural Climate Solutions</h1>
        <h2>+ human well-being</h2>
        <h3>A global survey of experts</h3>
        <div className={style.sidebar}>
          
          <div className={style.buttons}>
            <Input
              placeholder="Name"
              onChange={(e) => {
                //updateUserName(e.target.value);
                setUname(e.target.value);
              }}
              value={uname}
            />

            <Input
              placeholder="Organization"
              onChange={(e) => {
                //updateOrganization(e.target.value);
                setOrg(e.target.value);
              }}
              value={org}
            />

            <Input
              valid={!tried || validateEmail(email)}
              placeholder="E-mail address"
              onChange={(e) => {
                //updateMail(e.target.value);
                setEmail(e.target.value);
              }}
              value={email}
            />

            <div ref={buttonRef} className={style.button}>
              <Button disabled={loading} onClick={onParticipate}>Participate</Button>
            </div>
          </div>
        </div>
        <div className={style.footNote}>
          <p>
          A joint research project between McGill University, The Nature Conservancy, and Pomona College
          </p>
        </div>
      </div>
      <img className={`${style.pic1} ${hovered ? style.hovered : ''}`} src={welcome1} alt="welcome1" />
    </div>
  );
};

export default ParticipatePage;
