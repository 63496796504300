import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Question from '../../components/question/question';
import { Biom, QuestionProps } from '../../types';
import _ from 'lodash';

import style from './question-multiselect-with-biom.module.scss';
import Checkbox from '../../components/checkbox-old/checkbox_old';

import { BiomIcons } from '../util';
import useSurveyStore from '../../store/survey.store';
import Tooltip from '../../components/tooltip/tooltip';

import info from '../../assets/tooltip/info.png';

const MAX_ANSWERS = 2;

const MultiselectWithBiomQuestion: React.FC<QuestionProps> = ({
  question,
  currentQuestion,
  totalQuestions,
  answerQuestion,
}) => {
  const { setAlert } = useSurveyStore();

  const [selected, setSelected] = useState<string[]>(
    question.previous_answer?.answers[0].answers.map((e) => e.answer_id) ?? []
  );

  useEffect(() => {
    answerQuestion(
      question.page.questions[0].question_id,
      question.page.questions[0].possible_answers.filter((e) =>
        selected.includes(e.answer_id)
      ) ?? []
    );
  }, [answerQuestion, question.page.questions, selected]);

  const onSelect = useCallback(
    (answer: string) => {
      if (selected.length === MAX_ANSWERS && !selected.includes(answer)) {
        setAlert({
          message: `You can only select ${MAX_ANSWERS} answers`,
          type: 'warning',
        });
        return;
      }
      if (selected.includes(answer)) {
        setSelected(selected.filter((s) => s !== answer));
      } else {
        setSelected([...selected, answer]);
      }
    },
    [selected, setAlert]
  );

  const grouped = useMemo(() => {
    return _.groupBy(question.page.questions[0].possible_answers, (item) =>
      item.group_display?.at(0)
    );
  }, [question.page.questions]);

  return (
    <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
      <div className={style.question}>
        <div className={style.title}>
          <h1 className={style.main_title}>{question.page.page_title}</h1>
          <h2 className={style.sub_title} 
            dangerouslySetInnerHTML={{
              __html: question.page.page_display
                ? question.page.page_display
                : '',
            }}
          ></h2>
        </div>{' '}
        <div className={style.answers}>
          {Object.entries(grouped).map(([biom, answers]) => {
            const groupedAnswers = _.groupBy(answers, (item) =>
              item.group_display?.at(1)
            );

            return (
              <div key={biom} className={style.answer}>
                <div className={style.title}>
                  <div className={style.biomIcon}>
                    <img src={BiomIcons[biom as Biom]} alt={`${biom}-icon`} />
                  </div>
                  <h3>{biom}</h3>
                </div>
                <div className={style.content}>
                  {Object.entries(groupedAnswers).map(([group, answers]) => {
                    return (
                      <div key={group}>
                        <h4>{group}</h4>
                        {answers.map((answer, index) => {
                          return (
                            <div key={index} className={style.flex}>
                              <Checkbox
                                checked={selected.includes(answer.answer_id)}
                                onChange={() => onSelect(answer.answer_id)}
                                label={answer.answer_display}
                              />
                              <Tooltip
                                text={
                                  answer.answer_helper
                                    ? answer.answer_helper
                                    : 'Tooltip not defined.'
                                }
                              >
                                <img src={info} alt="info" />
                              </Tooltip>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                
              </div>
            );
          })}
        </div>
      </div>
    </Question>
  );
};

export default MultiselectWithBiomQuestion;
