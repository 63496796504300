import React, { useCallback, useEffect, useState } from 'react';
import Checkbox from '../../components/checkbox/checkbox';
import Question from '../../components/question/question';
import Region from '../../components/region/region';
import { AnswerData, NewPossibleAnswer, QuestionProps } from '../../types';

import style from './question-ncs-constraints.module.scss';
import _ from 'lodash';
import Card from '../../components/card/card';
import CardPlaceholder from '../../components/card-placeholder/card-placeholder';
import info from '../../assets/tooltip/info.png';

const NCSConstraintsQuestion: React.FC<QuestionProps> = ({
  question,
  currentQuestion,
  totalQuestions,
  answerQuestion,
}) => {
  const [answers, setAnswers] = React.useState<
    {
      answer_id: string;
      question_id: string;
    }[]
  >(
    _.flatMap(
      question.previous_answer?.answers?.map((a: AnswerData) =>
        a.answers.map((e) => {
          return {
            answer_id: e.answer_id,
            question_id: a.question_id,
          };
        })
      )
    ) ?? []
  );

  const [firstColumnSelected, setFirstColumnSelected] = React.useState<boolean>(
    answers.length > 0 ? true : false
  );

  const [helperText, setHelperText] = React.useState<string | undefined | null>(
    ''
  );

  const [freeTextAnswers, setFreeTextAnswers] = React.useState<
    {
      answer_id: string;
      value: string;
      question_id: string;
    }[]
  >(
    _.flatMap(
      question.previous_answer?.answers?.map((e) => {
        return e.answers.map((a) => {
          return {
            answer_id: a.answer_id,
            value: a.answer_display,
            question_id: e.question_id,
          };
        });
      })
    ) ?? []
  );

  const onSelect = useCallback(
    (answer_id: string, question_id: string) => {
      setFirstColumnSelected(true);

      // remove answers with same question_id if answer_id is none
      if (answer_id.startsWith('none')) {
        if (answers.find((e) => e.answer_id === answer_id)) {
          setAnswers(answers.filter((a) => a.question_id !== question_id));
        } else {
          setAnswers([
            ...answers.filter((a) => a.question_id !== question_id),
            {
              answer_id,
              question_id,
            },
          ]);
        }
      } else {
        if (
          answers.find(
            (e) => e.answer_id === answer_id && e.question_id === question_id
          )
        ) {
          setAnswers(
            answers.filter(
              (a) =>
                !(a.answer_id === answer_id && a.question_id === question_id)
            )
          );
        } else {
          setAnswers([
            ...answers.filter((e) => !(e.question_id === question_id)),
            {
              answer_id,
              question_id,
            },
          ]);
        }
      }
    },
    [answers]
  );

  const onFreeTextChange = useCallback(
    (answer_id: string, value: string, question_id: string) => {
      if (!freeTextAnswers.find((e) => e.answer_id === answer_id)) {
        setFreeTextAnswers([
          ...freeTextAnswers,
          {
            answer_id,
            value,
            question_id,
          },
        ]);
      } else {
        setFreeTextAnswers(
          freeTextAnswers.map((e) => {
            if (e.answer_id === answer_id) {
              return {
                answer_id,
                value,
                question_id,
              };
            }
            return e;
          })
        );
      }
    },
    [freeTextAnswers]
  );

  useEffect(() => {
    question.page.questions.forEach((q) => {
      answerQuestion(
        q.question_id,
        answers
          .filter((f) => f.question_id === q.question_id)
          .map((e) => {
            return {
              ...q.possible_answers?.find(
                (a: NewPossibleAnswer) => a.answer_id === e.answer_id
              ),
              answer_display: freeTextAnswers.find(
                (v) => v.answer_id === e.answer_id
              )?.value,
            };
          }) as NewPossibleAnswer[]
      );
    });
  }, [answerQuestion, answers, freeTextAnswers, question.page.questions]);

  return (
    <Question currentQuestion={currentQuestion} totalQuestions={totalQuestions}>
      <div className={style.regionalExpertise}>
        <div className={style.title}>
          <h2
            dangerouslySetInnerHTML={{
              __html: question.page.page_display
                ? question.page.page_display
                : '',
            }}
          ></h2>
        </div>{' '}
        <div className={style.container}>
          {question.page.extra_data != null && (
            <div className={style.region}>
              <Region
                selected_region={question.page.extra_data?.selected_region}
              />
            </div>
          )}
          <div className={style.answers}>
            <p>{question.page.questions[0].question_display}</p>
            <div className={style.flex}>
              <div className={style.card}>
                <CardPlaceholder>
                  <h1></h1>
                </CardPlaceholder>
                <div className={style.cardAnswers}>
                  {question.page.questions[0].possible_answers?.map(
                    (e: NewPossibleAnswer, j: number) => {
                      return (
                        <div
                          className={style.cardAnswer}
                          key={j}
                          onMouseEnter={() => {
                            setHelperText(e.answer_helper);
                          }}
                          onMouseLeave={() => {
                            setHelperText('');
                          }}
                        >
                          {e.user_input ? 'Other' : e.answer_display}
                          <img src={info} alt="info" />
                        </div>
                      );
                    }
                  )}
                </div>
                <div
                  className={style.helper_text}
                  dangerouslySetInnerHTML={{
                    __html: helperText ? helperText : '',
                  }}
                ></div>
              </div>

              {question.page.questions.map((q, i) => {
                //if (i > 0 && !firstColumnSelected) {
                //  return;
                //}

                return (
                  <div className={style.cardNarrow} key={i}>
                    <Card>
                      <h1>{q.group_display}</h1>
                    </Card>
                    <div className={style.cardAnswers}>
                      {q.possible_answers?.map(
                        (e: NewPossibleAnswer, j: number) => {
                          return (
                            <div className={style.cardAnswer} key={j}>
                              <Checkbox
                                onChange={() =>
                                  onSelect(e.answer_id, q.question_id)
                                }
                                checked={
                                  answers.find(
                                    (a) => a.answer_id === e.answer_id
                                  )
                                    ? true
                                    : false
                                }
                                label={
                                  e.user_input ? 'Other:' : e.answer_display
                                }
                                hasInput={e.user_input}
                                inputPlaceholder="Please specify"
                                input={
                                  freeTextAnswers.find(
                                    (v) => v.answer_id === e.answer_id
                                  )?.value ?? ''
                                }
                                onInput={(value) =>
                                  onFreeTextChange(
                                    e.answer_id,
                                    value,
                                    q.question_id
                                  )
                                }
                                showLabel={false}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Question>
  );
};

export default NCSConstraintsQuestion;
